import React, { Component, Fragment } from 'react';
import Draggabilly from 'draggabilly';
import { store } from 'react-notifications-component';
import { Icon } from 'react-icons-kit';
import { Loader } from 'react-loaders';
import { translate } from 'react-i18next';
import { rssSquare } from 'react-icons-kit/fa/rssSquare';
import { ic_shopping_cart } from 'react-icons-kit/md/ic_shopping_cart';

import RSS from 'components/RSS/RSS';
import CustomModal from 'components/CustomModal/CustomModal';
import ShoppingCart from 'components/ShoppingCart/ShoppingCart';

import Utils from 'utils/Utils';
import ApiService from 'services/ApiService';
import LocalStorage from 'utils/LocalStorage';

import Logo from 'static/img/logo.png';
import LogoIGN from 'static/img/logo_ign.png';
import LogoFototeca from 'static/img/logo_fototeca.png';

import './Header.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      langSelected: LocalStorage.getString('language') || 'es',
      opened: false,
      option: undefined,
      rssData: {},
      shoppingCart: this.props.shoppingCart || [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ shoppingCart: nextProps.shoppingCart });
    }
  }

  changeLanguage = (lang) => {
    this.setState({ langSelected: lang }, () => {
      LocalStorage.putString('language', lang);
      window.location.reload();
    });
  }

  goUrl = (url) => {
    window.open(url);
  }

  goStart = () => {
    window.location.href = '/fototeca';
  }

  closeModal = () => {
    this.setState({ opened: false, option: undefined });
  }

  openRSS = () => {
    const _this = this;
    this.setState({ opened: true, option: 'blocking' });
    ApiService.getRequest(CONFIGURATIONS.rss_url)
    .then((response) => {
      const res = {};
      const parts = response.split('<entry>');
      res.title = parts[0].split('<title>')[1].split('</title>')[0];
      res.subtitle = parts[0].split('<tagline>')[1].split('</tagline>')[0];
      const entries = [];
      parts.forEach((p) => {
        if (p.indexOf('</entry>') > -1) {
          const rawDate = new Date(parseInt(p.split('<pubDate>')[1].split('</pubDate>')[0]));
          const entry = {
            title: p.split('<title>')[1].split('</title>')[0],
            fullDate: parseInt(p.split('<pubDate>')[1].split('</pubDate>')[0]),
            date: Utils.formatDate(rawDate),
            content: p.split('<description>')[1].split('</description>')[0].replaceAll('<![CDATA[', '').replaceAll(']]>', '').trim(),
          };

          entries.push(entry);
        }
      });

      res.entries = entries;
      _this.setState({ option: 'rss', rssData: res }, () => {
        setTimeout(() => {
          if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
            const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
              containment: 'body',
              handle: '.customModalTitle',
            });

            draggable.enable();
          }
        }, 50);
      });
    }).catch((error) => {
      _this.closeModal();
      console.error(error);
    });
  }

  openCart = () => {
    if (this.state.shoppingCart.length > 0) {
      this.setState({ opened: true, option: 'cart' }, () => {
        setTimeout(() => {
          if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
            const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
              containment: 'body',
              handle: '.customModalTitle',
            });

            draggable.enable();
          }
        }, 50);
      });
    } else {
      store.addNotification({
        title: this.props.t('header.warning'),
        message: this.props.t('header.empty_cart'),
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }
  }

  buyCart = () => {
    const _this = this;
    _this.closeModal();
    _this.props.block();
    const sendData = { fotogramas: _this.state.shoppingCart };
    ApiService.postRequest(API_REST + 'api/virtual_shop/buy', sendData)
    .then((response) => {
      _this.props.unblock();
      const url = response.data.url;
      window.open(url);
      setTimeout(() => {
        _this.props.updateShoppingCart([]);
      }, 2000);
    }).catch((error) => {
      _this.props.unblock();
      console.error(error);
    });
  }

  render() {
    const { t, updateShoppingCart } = this.props;
    const { langSelected, opened, option, rssData, shoppingCart } = this.state;

    return (
      <Fragment>
        <header className='h-desktop'>
          <div className='logo-ign'>
            <img src={LogoIGN} alt='Logo' onClick={this.goUrl.bind(null, 'https://www.mitma.gob.es/')}/>
          </div>
          <div className='logo'>
            <img src={Logo} alt='Logo' onClick={this.goUrl.bind(null, 'https://www.ign.es/web/ign/portal')}/>
          </div>
          <div className='visor-title'>
            <div className='logo-fototeca' onClick={this.goStart}>
              <img src={LogoFototeca} alt='Logo'/>
            </div>
            <div className="label-container" onClick={this.goStart}>
              <label className='title'>{t('header.title')}</label>
              <label className='subtitle'>{t('header.subtitle')}</label>
            </div>
          </div>
          <div className='visor-language'>
            <div style={{ display: 'grid' }}>
              <span className={langSelected === 'es' ? 'lang-selected' : 'lang-option'} onClick={this.changeLanguage.bind(null, 'es')}>es</span>
              <span className={langSelected === 'en' ? 'lang-selected' : 'lang-option'} onClick={this.changeLanguage.bind(null, 'en')}>en</span>
            </div>
            <span title='RSS'><Icon size={'24px'} icon={rssSquare} onClick={this.openRSS} /></span>
            <span className={shoppingCart.length > 0 ? 'shopping-cart' : 'shopping-cart-empty'} title={t('header.cart')}><Icon size={'24px'} icon={ic_shopping_cart} onClick={this.openCart} /></span>
            { shoppingCart.length > 0 ?
              <span className='shopping-number' onClick={this.openCart}>{shoppingCart.length}</span>
            : null }
          </div>
        </header>
        <CustomModal open={opened} blocking={option === 'blocking'} title={option === 'rss' ? t('header.last_news') : option === 'cart' ? t('header.cart') : undefined} onClose={this.closeModal}  modalClass={option + '-modalBox'}>
          { option === 'blocking' ?
            <div className='block-loader-container'>
              <Loader active type={'ball-triangle-path'} color='#364B5F'/>
            </div>
          : option === 'rss' ?
            <RSS data={rssData} />
          : option === 'cart' ?
            <ShoppingCart shoppingCart={shoppingCart} updateShoppingCart={updateShoppingCart} buyCart={this.buyCart} closeModal={this.closeModal} />
          : null }
        </CustomModal>
      </Fragment>
    );
  }
}

export default translate()(Header);
