import React, { Component } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { landscape } from 'react-icons-kit/entypo/landscape';
import { chevronUp } from 'react-icons-kit/fa/chevronUp';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { Icon } from 'react-icons-kit';
import { saveAs } from 'file-saver';

import Button from 'components/Button/Button';
import Warning from 'components/Warning/Warning';
import CustomModal from 'components/CustomModal/CustomModal';
import MTNSelector from 'components/MTNSelector/MTNSelector';
import ActionButton from 'components/ActionButton/ActionButton';
import ApiService from 'services/ApiService';
import Utils from 'utils/Utils';
import { getBbox, addGeoJSONLayer, removeUploadedLayers, getExtentByCoordinates } from 'utils/Visor';

import MTN50 from 'static/mtn/mtn50.geojson';
import MTNIcon from 'static/img/mtnIcon.svg';
import MTNIconSelected from 'static/img/mtnIconSelected.svg';

import { mapjs } from 'utils/Visor';
import './Filters.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: this.props.filters.year,
      flightType: this.props.filters.flightType,
      scale: this.props.filters.scale,
      gsd: this.props.filters.gsd,
      query: this.props.filters.query,
      results: this.props.results,
      open: false,
      modalOption: undefined,
      actionSelected: undefined,
      mtnSelected: undefined,
      mtnValue: '',
      hidden: false || (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
    };
  }

  onChangeFormField = (name, evt) => {
    const _this = this;
    let newState = this.state;
    let value = evt.target.value;
    newState[name] = value;
    this.setState(newState, () => {
      const filters = {
        year: newState.year,
        flightType: newState.flightType,
        scale: newState.scale,
        gsd: newState.gsd,
        query: newState.query,
      };

      _this.props.updateFilters(filters);
    });
  }

  search = () => {
    const _this = this;
    const { flightType, query, actionSelected, mtnSelected } = this.state;
    let { scale, gsd, year } = this.state;
    let filters = {};
    scale = scale || '';
    gsd = gsd || '';
    year = year || '';
    let newScale = parseInt(scale, 10);
    let newGsd = parseInt(gsd, 10);
    let newYear = parseInt(year, 10);

    if (!Utils.isEmpty(flightType) || !Utils.isEmpty(query) || !Utils.isEmpty(scale) || !Utils.isEmpty(gsd) ||
      !Utils.isEmpty(year) || actionSelected === 'bbox' || (actionSelected === 'mtn' && mtnSelected !== undefined)) {
      const allowScale = scale === '' || !isNaN(newScale);
      const allowGsd = gsd === '' || !isNaN(newGsd);
      const allowYear = year === '' || !isNaN(newYear);
      if (allowScale && allowYear && allowGsd) {
        this.props.block();
        filters = {
          query: query,
          flightType: flightType !== 'default' ? flightType : undefined,
        };

        if ((newScale.toString().length === scale.trim().length) && !isNaN(newScale)) {
          filters.scale = newScale.toString();
        } else {
          filters.scale = '';
        }

        if ((newYear.toString().length === year.trim().length) && !isNaN(newYear)) {
          filters.year = newYear.toString();
        } else {
          filters.year = '';
        }

        if ((newGsd.toString().length === gsd.trim().length) && !isNaN(newGsd)) {
          filters.gsd = newGsd.toString();
        } else {
          filters.gsd = '';
        }

        if (actionSelected === 'bbox') {
          filters.bbox = getBbox();
        } else if (actionSelected === 'mtn' && mtnSelected !== undefined) {
          filters.bbox = mtnSelected;
        }

        ApiService.postRequest(API_REST + 'api/flight/search', filters)
          .then((response) => {
            _this.props.unblock();
            const results = response.data || [];
            _this.props.updateResults(results);
            _this.setState({ results });
            if (!_this.state.mobile) {
              _this.toogleHidden();
            }
          }).catch((error) => {
            _this.props.unblock();
          });
      } else {
        this.handleOpen('wrong-format');
      }
    } else {
      this.handleOpen('empty');
    }
  }

  export = () => {
    let text = 'CÓDIGO\tNOMBRE\tTIPO\tAÑO\r\n';
    this.state.results.forEach((item) => {
      const row = `${item.codVuelo}\t${item.nomVuelo}\t${item.tipo}\t${item.año}\r\n`
      text += row;
    });

    const file = new File([text], 'results.csv', { type: 'text/csv;charset=utf-8' });
    saveAs(file);
  }

  clean = () => {
    removeUploadedLayers();
    const _this = this;
    this.props.block();
    this.props.updateResults([]);
    this.setState({
      year: undefined,
      flightType: 'default',
      scale: undefined,
      gsd: undefined,
      query: '',
      results: [],
      actionSelected: undefined,
      mtnSelected: undefined,
      mtnValue: '',
    }, () => {
      _this.props.unblock();
      document.getElementById('queryText').value = '';
      document.getElementById('selectScale').value = '';
      document.getElementById('selectGsd').value = '';
      document.getElementById('selectYear').value = '';
      _this.setState({ flightType: undefined });
    });
  }

  handleOpen = (modalOption) => {
    this.setState({ open: true, modalOption: modalOption }, () => {
      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          document.querySelector('#sheetMTN50').focus();
          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
        }
      }, 50);
    });
  }

  handleClose = () => {
    this.setState({ open: false, modalOption: undefined, actionSelected: undefined });
  }

  selectOption = (selected) => {
    removeUploadedLayers();
    if (this.state.actionSelected === selected) {
      this.setState({ actionSelected: undefined, mtnSelected: undefined, mtnValue: '' });
    } else {
      this.setState({ actionSelected: selected }, () => {
        if (selected === 'mtn') {
          this.handleOpen('mtn');
        }
      });
    }
  }

  getSheetValue = (evt) => {
    let value = evt.target.value;
    if (value.length < 4) {
      while (value.length < 4) {
        value = '0' + value;
      }
    }

    this.setState({ mtnValue: value });
  }

  showSheet = (search) => {
    const _this = this;
    const file = MTN50;
    const property = 'MTN50_CLAS';
    const value = this.state.mtnValue;
    Utils.getFile(file, false).then((geojson) => {
      const features = [].concat(geojson.features);
      geojson.features = features.filter((item) => {
        return item.properties[property] === value || item.properties[property].indexOf(value) > -1;
      });

      if (geojson.features.length > 1) {
        geojson.features = features.filter((item) => {
          return item.properties[property] === value;
        });
      }

      if (geojson.features.length === 1) {
        const extent = getExtentByCoordinates(geojson.features[0].geometry);
        removeUploadedLayers();
        addGeoJSONLayer({ source: geojson, extract: false, name: 'Hoja ' + value }, {
          displayInLayerSwitcher: false,
        }, 'Polygon', true, CONFIGURATIONS.fotograms_zoom, CONFIGURATIONS.max_zoom, true);
        if (this.state.mobile && !search) {
          this.toogleHidden();
        }
        _this.setState({ open: false, modalOption: undefined, mtnSelected: extent, mtnValue: '' });
        if (search) {
          this.search();
        }
      } else {
        _this.handleOpen('not-found-mtn');
      }
    });
  }

  toogleHidden = () => {
    const inv = !this.state.hidden;
    this.setState({ hidden: inv }, () => {
      if (this.state.mobile) {
        const elemResult = document.querySelector('div.resultsContainer');
        if (inv && elemResult !== null) {
          elemResult.style.display = 'none';
        } else if (elemResult !== null) {
          elemResult.style.display = 'block';
        }
        const navElem = document.querySelector('.search-menu.nav-wrapper');
        if (inv && navElem !== null) {
          navElem.classList.remove('up');
          // Se espera para que se complete la animación de ocultación
          setTimeout(() => {navElem.classList.remove('show');}, 500);
          setTimeout(() => {
            mapjs.refresh();
          }, 500);
        } else if (navElem !== null) {
          navElem.classList.add('show');
          // Se espera para que la animación de despligue se vea correctamente
          setTimeout(() => {navElem.classList.add('up');}, 100);
        }
        const navElem2 = document.querySelector('.tools-menu.nav-wrapper');
        if (inv && navElem2 !== null) {
          navElem2.classList.remove('bottom-wrapper');
        } else if (navElem2 !== null) {
          navElem2.classList.add('bottom-wrapper');
        }
      } else {
        const elem = document.querySelector('div.resultsContainer > div.table.row > div');
        if (inv && elem !== null) {
          elem.style.height = 'calc(100vh - 300px)';//busqueda oculta
        } else if (elem !== null) {
          elem.style.height = 'calc(100vh - 550px)';//normal
        }
      }
    });
  }

  enterKey = (evt) => {
    const keyCode = evt.charCode || evt.which;
    if (keyCode === 13) {
      this.search();
    }
  }

  render() {
    const { t } = this.props;
    const { flightType, query, scale, gsd, year, results, open, modalOption, actionSelected, hidden } = this.state;

    return (
      <div className='filtersContainer'>
        <div className='filtersTitleContainer row' onClick={this.state.mobile ? this.toogleHidden : null}>
          <span>{t('filters.search_flights')} {this.state.mobile && this.state.results.length > 0 ? '(' + this.state.results.length +')' : ''}</span>
          {this.state.mobile ?
          (hidden ?
          <span className='collapse-icon'><Icon size={'1.2rem'} icon={chevronDown} /></span>
          : <div className='collapse-button'>{t('others.returnmap')}</div>)
          : (hidden ?
          <div className='collapse-button' onClick={this.toogleHidden}>{t('others.showfilters')}</div>
          : <span className='collapse-icon' onClick={this.toogleHidden}><Icon size={'1.2rem'} icon={hidden ? chevronDown : chevronUp} /></span>)
          }
        </div>
        {!hidden ?
          <div>
            <div className='filtersFormContainer row'>
              <table>
                <tbody>
                  <tr>
                    <td className='attribute-selector-field-title'>{t('filters.on_view')}</td>
                    <td className='attribute-selector-field-value'>
                      <div className='buttonsContainer row'>
                        <ActionButton id='selectExtent' icon={landscape} size={'20px'} rounded={true} tooltip={t('filters.view_extend')}
                          selected={actionSelected === 'bbox'} onClick={this.selectOption.bind(null, 'bbox')} />
                        <ActionButton id='selectMTN' icon={actionSelected === 'mtn' ? MTNIconSelected : MTNIcon} size={'24px'} rounded={true}
                          onClick={this.selectOption.bind(null, 'mtn')} selected={actionSelected === 'mtn'}
                          notInside={true} imageStyle={{ width: '24px', marginLeft: '2px' }} tooltip={t('filters.mtn_sheet')} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='attribute-selector-field-title'>{t('filters.text')}</td>
                    <td className='attribute-selector-field-value'>
                      <input type='text' id='queryText' name='queryText' defaultValue={query} onChange={this.onChangeFormField.bind(null, 'query')} onKeyPress={this.enterKey.bind(this)} />
                    </td>
                  </tr>
                  <tr>
                    <td className='attribute-selector-field-title'>{t('filters.flight_type')}</td>
                    <td className='attribute-selector-field-value'>
                      <select id='selectFlightType' value={flightType} defaultValue={'default'} onChange={this.onChangeFormField.bind(null, 'flightType')}>
                        <option value='default'>{t('filters.select')}</option>
                        <option value='analogico'>{t('filters.analogic')}</option>
                        <option value='digital'>{t('filters.digital')}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className='attribute-selector-field-title'>{t('filters.scale')}</td>
                    <td className='attribute-selector-field-value'>
                      <input type='text' id='selectScale' name='queryScale' value={scale} onChange={this.onChangeFormField.bind(null, 'scale')} disabled={flightType === 'digital'} onKeyPress={this.enterKey.bind(this)} />
                    </td>
                  </tr>
                  <tr>
                    <td className='attribute-selector-field-title'>{t('filters.gsd')}</td>
                    <td className='attribute-selector-field-value'>
                      <input type='text' id='selectGsd' name='queryGsd' value={gsd} onChange={this.onChangeFormField.bind(null, 'gsd')} disabled={flightType === 'analogico'} onKeyPress={this.enterKey.bind(this)} />
                    </td>
                  </tr>
                  <tr>
                    <td className='attribute-selector-field-title'>{t('filters.date')}</td>
                    <td className='attribute-selector-field-value'>
                      <input type='text' id='selectYear' name='queryYear' value={year} onChange={this.onChangeFormField.bind(null, 'year')} onKeyPress={this.enterKey.bind(this)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='filtersButtonContainer row'>
              <Button text={t('filters.search')} active={true} onClick={this.search} />
              {results.length > 0 ?
                <Button text={t('filters.clean')} active={true} onClick={this.clean} />
                : null}
              {results.length > 0 ?
                <Button text={t('filters.export')} active={true} onClick={this.export} />
                : null}
            </div>
            <CustomModal open={open} onClose={this.handleClose} title={modalOption === 'mtn' ? t('filters.mtn_sheet') : undefined} modalClass={modalOption + '-modalBox'}>
              {modalOption === 'mtn' ?
                <MTNSelector getSheetValue={this.getSheetValue} showSheet={this.showSheet} only50={true} mobile={this.state.mobile} />
                : modalOption === 'not-found-mtn' ?
                  <Warning>
                    <p>{t('actions.sheet_not_found')}</p>
                  </Warning>
                  : modalOption === 'empty' ?
                    <Warning>
                      <p style={{ width: '160px', textAlign: 'center' }}>{t('filters.please')}</p>
                    </Warning>
                    : modalOption === 'wrong-format' ?
                      <Warning>
                        <p style={{ width: '160px', textAlign: 'center' }}>{t('filters.check')}</p>
                      </Warning>
                      : null}
            </CustomModal>
          </div>
          : null}
      </div>
    );
  }
}

export default translate()(Filters);
