import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';
import * as shp from 'shpjs';
import { store } from 'react-notifications-component';

import M from 'utils/Mapea';
import Utils from 'utils/Utils';
import ApiService from 'services/ApiService';
import LocalStorage from 'utils/LocalStorage';

const EDITION_COLOR = '#FFCC33';
const PROJECTION = 'EPSG:3857';
declare var ol;
declare var loadshp;
declare var CONFIGURATIONS;

export let mapjs;
export let mapInfojs;
let translate;
let select;
let moveEndEvent;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];
const MAX_EXTENT = [-4948286.970787384, 2003448.639353435, 4092073.238556983, 6665495.868522905];
const INFO_MAX_EXTENT = [-2482582.080466683, 3089127.3574806936, 941796.7867092132, 5544896.202226836];
const INFO_MOBILE_MAX_EXTENT = [-2511933.8993281904, 2252600.5199277173, 971148.6055707209, 6381423.039779797];
const INFO_SAFARI_EXTENT = [-2482582.080466683, 3052437.583903809, 941796.7867092132, 5581585.975803721];

export const initMap = (flight, features, updateFeatures, unblock, t, params, activateFlightFromPhotogram) => {
  const tooShort = window.innerHeight < 692;
  const lang = LocalStorage.getString('language') || 'es';
  M.language.setLang(lang);
  let zoom = tooShort ? 4 : 5;
  let center = [-428106.86611520057, 4334472.25393817];
  let projection = 'EPSG:3857';
  let defaultBase = 0;
  let photogram = undefined;
  let layers = [];
  try {
    const arrayParams = params.split('&');
    arrayParams.forEach((param) => {
      if (param.indexOf('center') > -1) {
        const values = param.split('=')[1].split(',');
        center = [parseFloat(values[0]), parseFloat(values[1])];
      } else if (param.indexOf('zoom') > -1) {
        const value = param.split('=')[1];
        zoom = parseInt(value, 10);
      } else if (param.indexOf('srs') > -1) {
        const value = param.split('=')[1];
        projection = value;
      } else if (param.indexOf('baseLayer') > -1) {
        const value = param.split('=')[1];
        defaultBase = parseInt(value, 10);
      } else if (param.indexOf('layers') > -1) {
        const value = param.split('=')[1];
        layers = value.split(',').map(l => l.replaceAll('*', ','));
      } else if (param.indexOf('photogram') > -1) {
        const value = param.split('=')[1];
        photogram = value.split(',');
      }
    });

    if (center[0] !== -428106.86611520057 && center[1] !== 4334472.25393817 && projection !== 'EPSG:3857') {
      center = ol.proj.transform(center, projection, 'EPSG:3857');
    }
  } catch (err) { }

  mapjs = M.map({
    container: 'map',
    center: center,
    zoom: zoom,
    minZoom: tooShort ? 4 : 5,
    maxZoom: 20,
  }, { viewExtent: MAX_EXTENT }, { showFullExtent: true });

  window.mapjs = mapjs;
  const drawLayer = mapjs.getLayers().filter((layer) => layer.name === '__draw__')[0];
  drawLayer.getImpl().getOL3Layer().setStyle(new ol.style.Style({
    fill: new ol.style.Fill({
      color: 'rgba(255, 255, 255, 0.5)'
    }),
    stroke: new ol.style.Stroke({
      color: EDITION_COLOR,
      width: 2,
    }),
  }));

  mapjs.getMapImpl().on('moveend', simpleMoveEnd);
  mapjs.getMapImpl().on('click', (evt) => {
    if (window.printStopClick !== true) {
      removeMapOverlays();
      if (flight() !== undefined) {
        mapjs.getMapImpl().forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
          let newSelected = [].concat(features());
          if (layer !== null && Utils.stringContainsString(feature.getGeometry().getType(), 'Point') && feature.getProperties()[CONFIGURATIONS.fotogram_layer_attr] !== undefined) {
            const ID = feature.getProperties()[CONFIGURATIONS.fotogram_layer_attr];
            if (newSelected.indexOf(ID) < 0) {
              newSelected.push(ID);
            } else {
              newSelected = [].concat(newSelected.filter((item) => {
                if (item === ID) {
                  removeCustomWMSLayer(ID);
                }

                return item !== ID;
              }));
            }

            window.mapSelectedFlight = flight();
            updateFeatures(newSelected, true);
          }
        });
      }
    }
  });

  mapjs.getMapImpl().on('pointermove', (evt) => {
    if (flight() !== undefined) {
      const marks = mapjs.getLayers().filter((layer) => layer.name === CONFIGURATIONS.marks_name)[0];
      if (marks !== null && marks !== undefined) {
        marks.getFeatures().forEach((f) => {
          f.setStyle(new M.style.Polygon({
            fill: {
              color: '#0041ff',
              opacity: 0,
            },
          }));
        });
      }

      mapjs.getMapImpl().forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (marks !== null && marks !== undefined) {
          if (Utils.stringContainsString(feature.getGeometry().getType(), 'point')) {
            marks.getFeatures().forEach((f) => {
              if (Utils.stringContainsString(f.getAttribute(CONFIGURATIONS.marks_layer_attr), feature.getProperties()[CONFIGURATIONS.fotogram_layer_attr])) {
                f.setStyle(new M.style.Polygon({
                  stroke: {
                    width: 2,
                    color: '#E74C3C',
                  },
                }));

                showInfoPopup(feature, t);
              } else {
                f.setStyle(new M.style.Polygon({
                  fill: {
                    color: '#0041ff',
                    opacity: 0,
                  },
                }));
              }
            });
          } else if (feature.getProperties()[CONFIGURATIONS.fotogram_layer_attr] !== undefined) {
            removeMapOverlays();
          }
        }
      });
    }
  });

  addConfiguredPlugins(t);
  addAdditionalLayers(mapjs, layers, () => {
    addConfiguredBaseLayersPlugin(mapjs, defaultBase, () => {
      mapjs.getMapImpl().once('postrender', (evt) => {
        unblock();
        activateFlightFromPhotogram(photogram);
      });
    });
  });

  addPopupEvents();
  addAllMosaicLayers(mapjs, t, layers);
};

const addPopupEvents = () => {
  const popupBtn = document.querySelector('.m-panel-popup .m-panel-btn.icon-help');
  const calendarBtn = document.querySelector('.m-panel-calendar .m-panel-btn.icon-help');
  if (popupBtn !== null && calendarBtn !== null) {
    popupBtn.addEventListener('click', () => {
      calendarBtn.disabled = true;
      const elem = document.querySelector('.m-panel-popup .m-panel-btn.g-cartografia-flecha-derecha');
      if (elem !== null) {
        elem.addEventListener('click', () => {
          calendarBtn.disabled = false;
          addPopupEvents();
        });
      }
    });

    calendarBtn.addEventListener('click', () => {
      popupBtn.disabled = true;
      const elem = document.querySelector('.m-panel-calendar .m-panel-btn.g-cartografia-flecha-derecha');
      if (elem !== null) {
        elem.addEventListener('click', () => {
          popupBtn.disabled = false;
          addPopupEvents();
        });
      }
    });
  }
}

export const initInfoMap = (geojson) => {
  mapInfojs = M.map({
    container: 'infoMap',
    center: [-428106.86611520057, 4334472.25393817],
    zoom: isMobile2() ? 4 : 5,
    minZoom: isMobile2() ? 4 : 5,
    maxZoom: 20,
  }, { viewExtent: isMobile2() ? INFO_MOBILE_MAX_EXTENT : isSafari() ? INFO_SAFARI_EXTENT : INFO_MAX_EXTENT }, { showFullExtent: true });

  mapInfojs.addLayers(new M.layer.WMS({
    url: 'https://www.ign.es/wms-inspire/cuadriculas?',
    name: 'Grid-ETRS89-lonlat-50k,Grid-REGCAN95-lonlat-50k',
    legend: 'MTN50',
    tiled: false,
    transparent: true,
    isBase: false,
  }, { minZoom: 9 }));

  if (geojson !== undefined) {
    const layer = new M.layer.GeoJSON({ source: geojson, extract: false }, {});
    layer.extract = false;
    mapInfojs.addLayers(layer);
    layer.setStyle(new M.style.Polygon({
      fill: {
        color: '#0041ff',
        opacity: 0.6,
      },
      stroke: {
        width: 2,
        color: '#0041ff',
      },
    }));

    layer.setZIndex(999999999999999999);
    setTimeout(() => {
      window.mapInfojs = mapInfojs;
      mapInfojs.setBbox(layer.getMaxExtent());
    }, 200);
  }
}

export const initCompareSelectionMap = (fotogram, flightLayer, extent) => {
  const compareSelectionMapjs = M.map({
    container: 'compareSelectionMap',
    bbox: extent,
    minZoom: 5,
    maxZoom: 20,
  }, { viewExtent: INFO_MAX_EXTENT }, { showFullExtent: true });

  addCustomWMSLayerComparator(compareSelectionMapjs, CONFIGURATIONS.fotograms_layer_url, flightLayer, fotogram);
}

export const initCompareMap = (container, url, layerName, extent, fotograms, t) => {
  let mapCompare = null;
  if (url !== null && layerName !== null && extent !== null && fotograms !== null) {
    mapCompare = M.map({
      container: container,
      bbox: extent,
      minZoom: 5,
      maxZoom: 20,
    }, { viewExtent: INFO_MAX_EXTENT }, { showFullExtent: true });
  } else {
    mapCompare = M.map({
      container: container,
      center: [-428106.86611520057, 4334472.25393817],
      zoom: 5,
      minZoom: 5,
      maxZoom: 20,
    }, { viewExtent: INFO_MAX_EXTENT }, { showFullExtent: true });
  }

  mapCompare.removeControls('panzoom');
  if (url !== null && layerName !== null && extent !== null && fotograms !== null) {
    fotograms.forEach((f) => {
      addCustomWMSLayerComparator(mapCompare, CONFIGURATIONS.fotograms_layer_url, layerName, f);
    });
  }

  addAdditionalLayers(mapCompare, [], () => {
    addConfiguredBaseLayersPlugin(mapCompare);
  });

  return mapCompare.getMapImpl();
}

export const addAllMosaicLayers = (map, t, activeLayers) => {
  const lang = LocalStorage.getString('language') || 'es';
  let counter = 0;
  ApiService.getRequest(API_REST + 'api/orthophoto')
    .then((response) => {
      const data = {};
      const groupsData = [];
      response.forEach((m) => {
        if (groupsData.indexOf(m.category_name) === -1 && m.category_hidden === false) {
          groupsData.push(m.category_name);
        }
      });

      groupsData.forEach((gd) => {
        const gdRows = [];
        response.forEach((m) => {
          if (gd === m.category_name && m.hidden === false) {
            gdRows.push(m);
          }
        });

        if (gdRows.length > 0) {
          data[gd] = gdRows;
          counter += gdRows.length;
        }
      });

      LocalStorage.put('mosaicsData', data);
      LocalStorage.putString('mosaicsTotal', counter);
      let zindex = 1000;
      Object.keys(data).forEach((gd) => {
        data[gd].forEach((d) => {
          addWMSLayer(map, d.name, lang === 'en' ? d.titleEn : d.title, d.url, d.version, true, { visibility: activeLayers.indexOf(d.name) > -1, displayInLayerSwitcher: false, queryable: false, zIndex: zindex }, lang === 'en' ? d.descriptionEn : d.description);
          zindex++;
        });
      });
    }).catch((error) => {
      console.error(error);
    });
}

export const isVisibleLayer = (layerName) => {
  let res = false;
  const filtered = mapjs.getRootLayers().filter(l => l.name === layerName && l.displayInLayerSwitcher === false);
  if (filtered.length > 0) {
    res = filtered[0].isVisible();
  }

  return res;
}

export const toogleVisibilityLayer = (layerName, t, callback) => {
  const filtered = mapjs.getRootLayers().filter(l => l.name === layerName && l.displayInLayerSwitcher === false);
  const layerFound = filtered.length > 0 ? filtered[0] : null;
  if (layerFound !== null && !layerFound.isVisible()) {
    const bbox = mapjs.getBbox();
    const bboxFormatted = [bbox.x.min, bbox.y.min, bbox.x.max, bbox.y.max];
    const width = document.querySelector('.m-mapea-container').offsetWidth;
    const height = document.querySelector('.m-mapea-container').offsetHeight;
    const urlCheck = layerFound.url.concat('SERVICE=WMS&VERSION=')
      .concat(layerFound.version)
      .concat('&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=')
      .concat(layerFound.name)
      .concat('&TILED=true&CRS=')
      .concat(mapjs.getProjection().code)
      .concat('&STYLES=&WIDTH=')
      .concat(width)
      .concat('&HEIGHT=')
      .concat(height)
      .concat('&BBOX=')
      .concat(bboxFormatted.join(','));
    M.dialog.info(t('visor.coverage'));
    setTimeout(() => {
      if (document.querySelector('div.m-dialog > div > div > div.m-button') !== null) {
        document.querySelector('div.m-dialog > div > div > div.m-button').innerHTML = '';
      }
    }, 10);
    M.proxy(false);
    M.remote.get(urlCheck).then((response) => {
      M.proxy(true);
      const rowImage = Buffer.from(response.text).toString('base64');
      const outputImg = document.createElement('img');
      outputImg.src = 'data:image/png;base64,'.concat(rowImage);
      const dialogs = document.querySelectorAll('div.m-dialog');
      Array.prototype.forEach.call(dialogs, (dialog) => {
        const parent = dialog.parentElement;
        parent.removeChild(dialog);
      });

      if (outputImg.outerHTML.length > ((width * height) / 165)) {
        layerFound.setVisible(!layerFound.isVisible());
        callback();
      } else {
        setTimeout(() => {
          M.dialog.error(t('visor.no_coverage'), t('visor.warning'));
        }, 10);
      }
    }).catch((err) => {
      M.proxy(true);
    });
  } else if (layerFound !== null) {
    layerFound.setVisible(!layerFound.isVisible());
    callback();
  }
}

export const isLoadedMap = () => mapjs !== undefined;

export const getScale = () => {
  return mapjs.getScale();
}

export const getZoom = () => {
  return mapjs.getZoom();
}

export const getBbox = () => {
  const bbox = mapjs.getBbox();
  return [bbox.x.min, bbox.y.min, bbox.x.max, bbox.y.max];
}

export const setBbox = (extent) => {
  mapjs.setBbox(extent);
}

export const openInfoDialog = (text) => {
  M.dialog.info(text);
}

export const getExtentByCoordinates = (geometry) => {
  let extent = [];
  if (geometry.type === 'Polygon') {
    extent = ol.proj.transformExtent(new ol.geom.Polygon(geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
  } else {
    extent = ol.proj.transformExtent(new ol.geom.MultiPolygon(geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
  }

  return extent;
}

export const extendExtent = (extent1, extent2) => {
  return ol.extent.extend(extent1, extent2);
}

export const bboxContainsCoordinate = (coords) => {
  return ol.extent.containsCoordinate(getBbox(), ol.proj.transform(coords, 'EPSG:4326', PROJECTION));
}

export const bboxContainsGeometry = (geom) => {
  let extent = [];
  if (geom !== null && geom.type === 'Polygon') {
    extent = ol.proj.transformExtent(new ol.geom.Polygon(geom.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
  } else if (geom !== null) {
    extent = ol.proj.transformExtent(new ol.geom.MultiPolygon(geom.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
  }

  return ol.extent.intersects(getBbox(), extent);
}

export const addWMSLayer = (map, name, legend, url, version, tiled, options, abstract) => {
  const args = {
    name: name,
    url: url,
    legend: legend,
    transparent: true,
    tiled: tiled,
    version: version,
    isBase: false,
  };

  const layer = new M.layer.WMS(args, options);
  layer.options.visibility = options.visibility;
  layer.options.displayInLayerSwitcher = options.displayInLayerSwitcher;
  layer.options.queryable = options.queryable;

  if (options && options.hasOwnProperty('visibility')) {
    layer.setVisible(options.visibility);
  }

  if (options && options.hasOwnProperty('displayInLayerSwitcher')) {
    layer.displayInLayerSwitcher = options.displayInLayerSwitcher;
  }

  if (options && options.hasOwnProperty('zIndex')) {
    layer.setZIndex(options.zIndex);
  }

  map.addLayers(layer);
  setTimeout(() => {
    if (abstract !== undefined && layer.capabilitiesMetadata !== undefined && layer.capabilitiesMetadata.abstract === '') {
      layer.capabilitiesMetadata.abstract = abstract;
    }
  }, 1000);

  return layer.getImpl().getExtent();
};

export const addCustomWMSLayer = (url, layerName, imageId, unblock) => {
  if (!isCustomWMSLayerActive(imageId)) {
    const source = new ol.source.ImageWMS({
      url: url,
      params: {
        LAYERS: `imagen${layerName}`,
        FORMAT: 'image/png',
        VERSION: '1.1.1',
        TRANSPARENT: true,
        IMAGEN: imageId,
      },
      serverType: 'mapserver'
    });

    const layer = new ol.layer.Image({
      visible: true,
      opacity: 1,
      zIndex: 5000,
      source: source,
    });

    mapjs.getMapImpl().addLayer(layer);
    source.once('imageloadend', (evt) => {
      unblock();
    });

    source.once('imageloaderror', (evt) => {
      unblock();
    });
  }
}

export const addCustomWMSLayerComparator = (map, url, layerName, imageId) => {
  const source = new ol.source.ImageWMS({
    url: url,
    params: {
      LAYERS: `imagen${layerName}`,
      FORMAT: 'image/png',
      VERSION: '1.1.1',
      TRANSPARENT: true,
      IMAGEN: imageId,
    },
    serverType: 'mapserver'
  });

  const layer = new ol.layer.Image({
    visible: true,
    opacity: 1,
    zIndex: 99999,
    source: source,
  });

  map.getMapImpl().addLayer(layer);
}

export const isCustomWMSLayerActive = (layerName) => {
  let res = false;
  const filtered = mapjs.getMapImpl().getLayers().getArray().filter((layer) => {
    return layer.getSource() !== null && layer.getSource().url_ !== undefined && layer.getSource().url_.indexOf(CONFIGURATIONS.fotograms_layer_url) > -1 && layer.getSource().params_['IMAGEN'] === layerName;
  });

  res = res || filtered.length > 0;
  return res;
}

export const setCustomWMSLayerOpacity = (layerName, newOpacity) => {
  const filtered = mapjs.getMapImpl().getLayers().getArray().filter((layer) => {
    return layer.getSource() !== null && layer.getSource().url_ !== undefined && layer.getSource().url_.indexOf(CONFIGURATIONS.fotograms_layer_url) > -1 && layer.getSource().params_['IMAGEN'] === layerName;
  });

  filtered[0].setOpacity(newOpacity);
}

export const getCustomWMSLayerOpacity = (layerName) => {
  let res = 0;
  const filtered = mapjs.getMapImpl().getLayers().getArray().filter((layer) => {
    return layer.getSource() !== null && layer.getSource().url_ !== undefined && layer.getSource().url_.indexOf(CONFIGURATIONS.fotograms_layer_url) > -1 && layer.getSource().params_['IMAGEN'] === layerName;
  });

  if (filtered.length > 0) {
    res = filtered[0].getOpacity();;
  }

  return res;
}

export const removeCustomWMSLayer = (layerName) => {
  const toDelete = mapjs.getMapImpl().getLayers().getArray().filter((layer) => {
    return layer.getSource() !== null && layer.getSource().url_ !== undefined && layer.getSource().url_.indexOf(CONFIGURATIONS.fotograms_layer_url) > -1 && layer.getSource().params_['IMAGEN'] === layerName;
  });

  if (toDelete.length > 0) {
    mapjs.getMapImpl().removeLayer(toDelete[0]);
  }
}

export const removeAllCustomWMSLayer = () => {
  const toDelete = mapjs.getMapImpl().getLayers().getArray().filter((layer) => {
    return layer.getSource() !== null && layer.getSource().url_ !== undefined && layer.getSource().url_.indexOf(CONFIGURATIONS.fotograms_layer_url) > -1 && layer.getSource().params_['IMAGEN'] !== undefined;
  });

  if (toDelete.length > 0) {
    toDelete.forEach((del) => {
      mapjs.getMapImpl().removeLayer(del);
    });
  }
}

export const centerOnFotogram = (id, coordinates) => {
  const coord = ol.proj.fromLonLat(coordinates, PROJECTION);
  mapjs.setCenter(coord);
}

export const centerOnMark = (geometry) => {
  let extent = [];
  if (geometry.type === 'Polygon') {
    extent = ol.proj.transformExtent(new ol.geom.Polygon(geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
  } else if (geometry.type === 'MultiPolygon') {
    extent = ol.proj.transformExtent(new ol.geom.MultiPolygon(geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
  }

  mapjs.setBbox(extent);
}

export const newGeoJSONLayer = (args, options) => {
  const res = new M.layer.GeoJSON(args, options || {});
  res.extract = false;
  return res;
}

export const addFlightLayers = (coverage, centers, marks, getSelectedFeatures, unblock, inverseCoverage) => {
  removeMapOverlays();
  const zoom = mapjs.getZoom();
  const sourceCenters = Object.assign({}, centers.source);
  const sourceMarks = Object.assign({}, marks.source);
  mapjs.getMapImpl().un('moveend', simpleMoveEnd);
  if (moveEndEvent !== undefined) {
    ol.Observable.unByKey(moveEndEvent);
  }

  simpleMoveEnd();
  if (zoom >= CONFIGURATIONS.min_zoom && zoom <= CONFIGURATIONS.fotograms_zoom - 1) {
    if (!mapContainsLayer(coverage.name)) {
      mapjs.addLayers(coverage);
      coverage.setZIndex(999999999999999999);
      coverage.setStyle(new M.style.Polygon({
        fill: {
          color: '#0041ff',
          opacity: 0.6,
        },
        stroke: {
          width: 2,
          color: '#0041ff',
        },
      }));
    }
  } else {
    try {
      mapjs.removeLayers([coverage]);
    } catch (err) { }
  }

  if (zoom >= CONFIGURATIONS.fotograms_zoom && zoom <= CONFIGURATIONS.max_zoom) {
    mapjs.removeLayers([centers, marks, inverseCoverage]);
    const centersFeatures = sourceCenters.features.filter((f) => {
      return f.geometry !== null && ol.extent.containsCoordinate(getBbox(), ol.proj.fromLonLat(f.geometry.coordinates, PROJECTION));
    });

    const marksFeatures = sourceMarks.features.filter((f) => {
      let extent = [];
      if (f.geometry !== null && f.geometry.type === 'Polygon') {
        extent = ol.proj.transformExtent(new ol.geom.Polygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      } else if (f.geometry !== null) {
        extent = ol.proj.transformExtent(new ol.geom.MultiPolygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      }

      return ol.extent.intersects(getBbox(), extent);
    });

    centers = newGeoJSONLayer({ source: Object.assign({}, sourceCenters), extract: false, name: CONFIGURATIONS.centers_name }, { displayInLayerSwitcher: false });
    marks = newGeoJSONLayer({ source: Object.assign({}, sourceMarks), extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
    centers.source.features = centersFeatures;
    marks.source.features = marksFeatures;
    mapjs.addLayers(centers);
    mapjs.addLayers(marks);
    mapjs.addLayers(inverseCoverage);
    centers.setZIndex(999999999999999999);
    marks.setZIndex(999999999999999999);
    inverseCoverage.setZIndex(999999999999999999);
    centers.setStyle(new M.style.Point({
      fill: {
        color: '#0041ff',
        opacity: 0.6,
      },
      stroke: {
        width: 2,
        color: '#0041ff',
      },
      radius: 7,
    }));

    marks.setStyle(new M.style.Polygon({
      fill: {
        color: '#0041ff',
        opacity: 0,
      },
    }));

    setTimeout(() => {
      centers.getFeatures().forEach((f) => {
        const selectedFeatures = getSelectedFeatures();
        if (Utils.arrayContainsString(selectedFeatures, f.getAttribute(CONFIGURATIONS.fotogram_layer_attr))) {
          f.setStyle(new M.style.Point({
            fill: {
              color: '#E74C3C',
              opacity: 0.6,
            },
            stroke: {
              color: '#E74C3C',
              width: 2,
            },
            radius: 7,
          }));
        }
      });

      inverseCoverage.getImpl().getOL3Layer().setStyle(new ol.style.Style({
        fill: new ol.style.FillPattern({
          pattern: 'crosses',
          color: '#E74C3C',
          scale: 2,
          offset: 0,
          background: '#E74C3C',
        }),
        stroke: new ol.style.Stroke({
          width: 2,
          color: '#E74C3C',
        }),
      }));
    }, CONFIGURATIONS.highlight_selected_timeout);
  } else {
    try {
      mapjs.removeLayers([centers, marks, inverseCoverage]);
    } catch (err) { }
  }

  moveEndEvent = mapjs.getMapImpl().on('moveend', complexMoveEnd.bind(null, coverage, centers, marks, inverseCoverage, sourceCenters, sourceMarks, getSelectedFeatures));
  mapjs.getMapImpl().once('postrender', (evt) => {
    unblock();
  });
}

export const addGeoJSONLayer = (args, options, geom, center, isMTN) => {
  const layer = new M.layer.GeoJSON(args, options || {});
  layer.extract = false;
  mapjs.addLayers(layer);
  if (Utils.stringContainsString(geom, 'Line')) {
    layer.setStyle(new M.style.Line({
      fill: {
        color: '#0041ff',
        opacity: 0.6,
      },
      stroke: {
        width: 2,
        color: '#0041ff',
      },
    }));
  } else if (Utils.stringContainsString(geom, 'Point')) {
    layer.setStyle(new M.style.Point({
      fill: {
        color: '#0041ff',
        opacity: 0.6,
      },
      stroke: {
        width: 2,
        color: '#0041ff',
      },
      radius: 7,
    }));
  } else if (Utils.stringContainsString(geom, 'Polygon')) {
    layer.setStyle(new M.style.Polygon({
      fill: {
        color: '#0041ff',
        opacity: isMTN ? 0 : 0.6,
      },
      stroke: {
        width: 3,
        color: '#0041ff',
      },
    }));
  }

  if (center) {
    setTimeout(() => {
      mapjs.setBbox(layer.getMaxExtent());
    }, CONFIGURATIONS.layer_added_timeout);
  }

  layer.setZIndex(999999999999999999);
  return layer;
};

export const removeUploadedLayers = () => {
  let layerRes = mapjs.getLayers().filter((layer) => (layer.name.startsWith('uploadedLayer') || layer.name.startsWith('Hoja ')));
  if (layerRes && layerRes.length > 0) {
    return mapjs.removeLayers(layerRes);
  }

  return false;
};

export const mapContainsLayer = (layerName) => {
  const layerRes = mapjs.getLayers().filter((layer) => (layer.name === layerName));
  return layerRes && layerRes.length > 0;
}

export const removeAllFlightLayers = () => {
  mapjs.getMapImpl().un('moveend', simpleMoveEnd);
  if (moveEndEvent !== undefined) {
    ol.Observable.unByKey(moveEndEvent);
  }

  // clearDrawLayer();
  removeAllCustomWMSLayer();
  const names = [CONFIGURATIONS.coverage_name, CONFIGURATIONS.centers_name, CONFIGURATIONS.marks_name];
  let layerRes = mapjs.getLayers().filter((layer) => {
    let res = false;
    names.forEach((name) => {
      res = res || layer.name.indexOf(name) > -1;
    });

    return res;
  });

  mapjs.getMapImpl().on('moveend', simpleMoveEnd);
  if (layerRes && layerRes.length > 0) {
    return mapjs.removeLayers(layerRes);
  }

  return false;
}

export const drawPolygon = (toogleHidden) => {
  removeDrawInteraction();
  clearDrawLayer();
  const drawLayer = mapjs.getLayers().filter((layer) => layer.name === '__draw__')[0];
  drawLayer.setZIndex(9999999999);
  const draw = new ol.interaction.Draw({
    source: drawLayer.getImpl().getOL3Layer().getSource(),
    type: 'Polygon',
    stopClick: true,
    style: new ol.style.Style({
      image: new ol.style.Circle({
        fill: new ol.style.Fill({
          color: '#478ffe',
        }),
        stroke: new ol.style.Stroke({
          width: 3,
          color: '#ffffff',
        }),
        radius: 6,
      }),
      fill: new ol.style.Fill({
        color: 'rgba(255, 255, 255, 0.5)'
      }),
      stroke: new ol.style.Stroke({
        color: EDITION_COLOR,
        width: 2,
      }),
    }),
  });

  mapjs.getMapImpl().addInteraction(draw);
  document.getElementById('map').style.cursor = 'crosshair';
  draw.once('drawend', (evt) => {
    setTimeout(() => {
      document.getElementById('map').style.cursor = 'default';
      const MFeature = M.impl.Feature.olFeature2Facade(evt.feature);
      drawLayer.addFeatures(MFeature);
      removeDrawInteraction();
      if (toogleHidden) {
        toogleHidden();
      }
    }, 100);
  });

  draw.once('drawstart', (evt) => {
    document.addEventListener('keydown', controlDrawWithKeyboard.bind(null, draw));
  });
}

export const hasDrawFeatures = () => {
  const drawLayer = mapjs.getLayers().filter((layer) => layer.name === '__draw__')[0];
  return drawLayer.getImpl().getOL3Layer().getSource().getFeatures().length > 0;
}

export const clearDrawLayer = () => {
  const drawLayer = mapjs.getLayers().filter((layer) => layer.name === '__draw__')[0];
  return drawLayer.clear();
}

export const getDrawnFeatures = () => {
  const drawLayer = mapjs.getLayers().filter((layer) => layer.name === '__draw__')[0];
  return drawLayer.getImpl().getOL3Layer().getSource().getFeatures();
}

export const getUploadedLayer = () => {
  return mapjs.getLayers().filter((layer) => layer.name.startsWith('uploadedLayer'))[0];
}

export const removeDrawInteraction = () => {
  document.releaseEvents(Event.KEYDOWN);
  const arrayInteractions = [].concat(mapjs.getMapImpl().getInteractions().getArray());
  arrayInteractions.forEach((interaction) => {
    if (interaction instanceof ol.interaction.Draw || interaction instanceof ol.interaction.Modify) {
      mapjs.getMapImpl().removeInteraction(interaction);
    }
  });
}

export const isLayerIntersectingExtent = (layer, extent) => {
  let intersecting = false;
  for (let f of layer.features) {
    if (f.geometry.type === 'Point') {
      if (ol.extent.containsCoordinate(extent, ol.proj.fromLonLat(f.geometry.coordinates, PROJECTION))) {
        intersecting = true;
        break;
      }
    } else if (f.geometry.type === 'Polygon') {
      const geom = new ol.geom.Polygon(f.geometry.coordinates);
      geom.transform('EPSG:4326', PROJECTION);
      if (geom.intersectsExtent(extent)) {
        intersecting = true;
        break;
      }
    } else if (f.geometry.type === 'MultiPolygon') {
      const geom = new ol.geom.MultiPolygon(f.geometry.coordinates);
      geom.transform('EPSG:4326', PROJECTION);
      if (geom.intersectsExtent(extent)) {
        intersecting = true;
        break;
      }
    }
  }

  return intersecting;
}

export const getFeaturesIntersectingExtent = (features, extent) => {
  let intersecting = [];
  features.forEach((f) => {
    if (f.geometry.type === 'Point') {
      if (ol.extent.containsCoordinate(extent, ol.proj.fromLonLat(f.geometry.coordinates, PROJECTION))) {
        intersecting.push(f);
      }
    } else if (f.geometry.type === 'Polygon') {
      const featureExtent = ol.proj.transformExtent(new ol.geom.Polygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      if (ol.extent.intersects(extent, featureExtent)) {
        intersecting.push(f);
      }
    } else if (f.geometry.type === 'MultiPolygon') {
      const featureExtent = ol.proj.transformExtent(new ol.geom.MultiPolygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      if (ol.extent.intersects(extent, featureExtent)) {
        intersecting.push(f);
      }
    }
  });

  return intersecting;
}

export const getFeaturesIntersectingGeometry = (features, geometry) => {
  let intersecting = [];
  features.forEach((f) => {
    if (f.geometry.type === 'Point') {
      if (geometry.getGeometry().intersectsCoordinate(ol.proj.fromLonLat(f.geometry.coordinates, PROJECTION))) {
        intersecting.push(f);
      }
    } else if (f.geometry.type === 'Polygon') {
      const featureExtent = ol.proj.transformExtent(new ol.geom.Polygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      if (ol.extent.intersects(geometry.getGeometry().getExtent(), featureExtent)) {
        intersecting.push(f);
      }
    } else if (f.geometry.type === 'MultiPolygon') {
      const featureExtent = ol.proj.transformExtent(new ol.geom.MultiPolygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      if (ol.extent.intersects(geometry.getGeometry().getExtent(), featureExtent)) {
        intersecting.push(f);
      }
    }
  });

  return intersecting;
}

export const addLayerFile = (file, block, unblock, t) => {
  block();
  const fileReader = new FileReader();
  fileReader.addEventListener('load', (e) => {
    try {
      let features = [];
      if ((file.type === 'application/vnd.google-earth.kml+xml') || Utils.checkFileExtension(file, '.kml')) {
        features = loadKMLLayer(mapjs.getLayers().length, fileReader.result, false);
      } else if (Utils.checkFileExtension(file, '.geojson') || Utils.checkFileExtension(file, '.json')) {
        features = loadGeoJSONLayer(mapjs.getLayers().length, fileReader.result);
      } else if (Utils.checkFileExtension(file, '.gpx')) {
        features = loadGPXLayer(mapjs.getLayers().length, fileReader.result);
      } else if (Utils.checkFileExtension(file, '.zip')) {
        const geojsonArray = [].concat(shp.parseZip(fileReader.result));
        geojsonArray.forEach((geojson) => {
          const localFeatures = loadGeoJSONLayer(mapjs.getLayers().length, geojson);
          if (localFeatures) {
            features = features.concat(localFeatures);
          }
        });
      } else {
        unblock(true);
        M.dialog.error(t('visor.wrong_format'));
      }

      if (!features.length) {
        unblock(true);
        M.dialog.info(t('visor.no_geom'));
      } else {
        if (features.length > CONFIGURATIONS.num_features_allowed) {
          unblock(true);
          M.dialog.info(t('visor.max_allowed1') + ' ' + CONFIGURATIONS.num_features_allowed + ' ' + t('visor.max_allowed2'));
        } else {
          let area = 0;
          features.forEach((f) => {
            if (Utils.stringContainsString(f.getGeometry().type, 'polygon')) {
              area += ol.extent.getArea(M.impl.utils.getFeaturesExtent([f]));
            }
          });

          if (area > CONFIGURATIONS.max_area_allowed) {
            unblock(true);
            M.dialog.info(t('visor.area_limit'));
          } else {
            centerFeatures(features);
            unblock(false);
          }
        }
      }
    } catch (err) {
      unblock(true);
      M.dialog.error(t('visor.error_loading'));
    }
  });


  if (Utils.checkFileExtension(file, '.zip')) {
    fileReader.readAsArrayBuffer(file);
  } else if (Utils.checkFileExtension(file, '.kml') || Utils.checkFileExtension(file, '.gpx') || Utils.checkFileExtension(file, '.geojson') || Utils.checkFileExtension(file, '.json')) {
    fileReader.readAsText(file);
  } else {
    unblock();
    M.dialog.error(t('visor.wrong_format'));
  }
}

export const highlightFeatures = (selectedFeatures) => {
  const allFeatures = getRenderedCenters();
  allFeatures.forEach((f) => {
    if (Utils.arrayContainsString(selectedFeatures, f.getAttribute(CONFIGURATIONS.fotogram_layer_attr))) {
      f.setStyle(new M.style.Point({
        fill: {
          color: '#E74C3C',
          opacity: 0.6,
        },
        stroke: {
          color: '#E74C3C',
          width: 2,
        },
        radius: 7,
      }));
    } else {
      f.setStyle(new M.style.Point({
        fill: {
          color: '#0041ff',
          opacity: 0.6,
        },
        stroke: {
          color: '#0041ff',
          width: 2,
        },
        radius: 7,
      }));
    }
  });
}

export const selectPrintArea = (scale, center, size, extent, onCloseModal, setPrintCenter) => {
  /****** BLOQUE PARA INICAR EL REESCALADO A PARTIR DE LA GEOMETRÍA YA MOVIDA POR EL USUARIO*******/
  const layers = mapjs.getMapImpl().getLayers().getArray().filter((l) => {
    return l.values_.name === 'print_layer';
  });

  if (layers.length > 0) {
    const oldExtent = layers[0].getSource().getFeatures()[0].getGeometry().getExtent();
    center = ol.extent.getCenter(oldExtent);
  } else {
    center = ol.proj.fromLonLat(center, PROJECTION);
  }

  /*************/
  clearPrintLayer();
  const vectorSource = new ol.source.Vector({});
  const layer = new ol.layer.Vector({
    source: vectorSource,
    name: 'print_layer',
  });

  let newSize = size;
  if (size[0] === 25 && size[1] === 25) {
    newSize = [22.5, 20.2];
  } else if (size[0] === 21.0 && size[1] === 29.7) {
    newSize = [18.4, 24.7];
  } else if (size[0] === 29.7 && size[1] === 21.0) {
    newSize = [27.4, 15.6];
  } else if (size[0] === 29.7 && size[1] === 42.0) {
    newSize = [26.1, 35.6];
  } else if (size[0] === 42.0 && size[1] === 29.7) {
    newSize = [38.4, 23.3];
  } else if (size[0] === 25 && size[1] === 30) {
    newSize = [22.6, 25.2];
  } else if (size[0] === 30 && size[1] === 25) {
    newSize = [26.8, 19.1];
  } else if (size[0] === 30 && size[1] === 40) {
    newSize = [26.7, 33.8];
  } else if (size[0] === 40 && size[1] === 30) {
    newSize = [36.6, 23.6];
  } else if (size[0] === 40 && size[1] === 50) {
    newSize = [36.6, 44];
  } else if (size[0] === 50 && size[1] === 40) {
    newSize = [47.4, 33.6];
  } else if (size[0] === 50 && size[1] === 50) {
    newSize = [47.4, 42.4];
  } else if (size[0] === 50 && size[1] === 60) {
    newSize = [47.2, 53.6];
  } else if (size[0] === 60 && size[1] === 50) {
    newSize = [56.4, 43.6];
  }

  if (ol.extent.intersects(getBbox(), extent)) {
    const areaExtent = ol.extent.getArea(extent);
    const areaBbox = ol.extent.getArea(getBbox());
    if (areaExtent >= areaBbox) {
      center = ol.extent.getCenter(getBbox());
    } else {
      mapjs.setCenter(center);
      mapjs.setBbox(extent);
    }
  } else {
    mapjs.setCenter(center);
    mapjs.setBbox(extent);
  }

  mapjs.getMapImpl().addLayer(layer);
  const width = ((newSize[0] / 100) * (scale)) / 2;
  const length = ((newSize[1] / 100) * (scale)) / 2;
  const minX = center[0] - width;
  const minY = center[1] - length;
  const maxX = center[0] + width;
  const maxY = center[1] + length;
  const polygon = ol.geom.Polygon.fromExtent([minX, minY, maxX, maxY]);
  const feature = new ol.Feature({
    geometry: polygon,
    name: 'print_feature',
  });

  const style = new ol.style.Style({
    stroke: new ol.style.Stroke({ color: '#009999', width: 5 }),
    fill: new ol.style.Fill({ color: 'rgba(0, 153, 153, 0.3)' }),
  });

  feature.setStyle(style);
  layer.setZIndex(99999999999999999999);
  vectorSource.addFeature(feature);
  select = new ol.interaction.Select({
    filter: (feature) => {
      return (feature.getProperties() || {}).hasOwnProperty('name') && feature.getProperties().name === 'print_feature';
    },
  });

  translate = new ol.interaction.Translate({
    features: select.getFeatures(),
    layers: [layer],
  });

  window.printStopClick = true;
  select.on('select', (evt) => {
    const selected = evt.selected;
    if (selected.length > 0) {
      const style_modify = new ol.style.Style({
        stroke: new ol.style.Stroke({ color: '#ff0000', width: 5 }),
        fill: new ol.style.Fill({ color: 'rgba(255, 255, 255, 0.3)' }),
      });

      selected.forEach((feature) => {
        feature.setStyle(style_modify);
      });

      mapjs.getMapImpl().addInteraction(translate);
      translate.on('translateend', (evt) => {
        const bbox = evt.features.getArray()[0].getGeometry().getExtent();
        setPrintCenter(ol.extent.getCenter(bbox));
      });

      document.addEventListener('keydown', (evt) => {
        if (evt.key === 'Escape') {
          mapjs.getMapImpl().removeLayer(layer);
          mapjs.getMapImpl().removeInteraction(translate);
          mapjs.getMapImpl().removeInteraction(select);
          window.printStopClick = undefined;
          onCloseModal();
        }
      });
    }
  });

  mapjs.getMapImpl().addInteraction(select);
  setPrintCenter(ol.extent.getCenter(feature.getGeometry().getExtent()));
  document.addEventListener('keydown', (evt) => {
    if (evt.key === 'Escape') {
      mapjs.getMapImpl().removeLayer(layer);
      mapjs.getMapImpl().removeInteraction(translate);
      mapjs.getMapImpl().removeInteraction(select);
      window.printStopClick = undefined;
      onCloseModal();
    }
  });
}

export const clearPrintLayer = () => {
  try {
    const notificationId = LocalStorage.getString('notificationId');
    store.removeNotification(notificationId);
  } catch (err) { }

  const layers = mapjs.getMapImpl().getLayers().getArray().filter((l) => {
    return l.values_.name === 'print_layer';
  });

  if (layers.length > 0) {
    mapjs.getMapImpl().removeLayer(layers[0]);
  }

  mapjs.getMapImpl().removeInteraction(translate);
  mapjs.getMapImpl().removeInteraction(select);
  window.printStopClick = undefined;
}

const getRenderedCenters = () => {
  let res = [];
  let layerRes = mapjs.getLayers().filter((layer) => {
    return layer.name.indexOf(CONFIGURATIONS.centers_name) > -1;
  });

  if (layerRes.length > 0) {
    res = layerRes[0].getFeatures();
  }

  return res;
}

export const getFeatureFromFotogram = (fotogram, centersLayer) => {
  let res = null;
  const allFeatures = centersLayer.source.features;
  const filtered = allFeatures.filter((f) => {
    return Utils.stringContainsString(f.properties[CONFIGURATIONS.fotogram_layer_attr], fotogram);
  });

  if (filtered.length > 0) {
    res = filtered[0];
  }

  return res;
}

const simpleMoveEnd = () => {
  if (mapjs.getScale() > CONFIGURATIONS.tools_min_scale) {
    const arrayInteractions = [].concat(mapjs.getMapImpl().getInteractions().getArray());
    for (let interaction of arrayInteractions) {
      if (interaction instanceof ol.interaction.Draw) {
        if (interaction.getOverlay().getLayersArray().length > 0) {
          try {
            const color = interaction.getOverlay().getLayersArray()[0].getStyle().getStroke().getColor();
            if (Utils.compareStrings(color, EDITION_COLOR) === 0) {
              document.getElementById('drawPolygon').click();
              break;
            }
          } catch (err) { }
        }
      }
    }
  }
}

const complexMoveEnd = (coverage, centers, marks, inverseCoverage, sourceCenters, sourceMarks, getSelectedFeatures) => {
  const zoom = mapjs.getZoom();
  simpleMoveEnd();
  if (zoom >= CONFIGURATIONS.min_zoom && zoom <= CONFIGURATIONS.fotograms_zoom - 1) {
    removeMapOverlays();
    if (!mapContainsLayer(coverage.name)) {
      mapjs.addLayers(coverage);
      coverage.setZIndex(999999999999999999);
      coverage.setStyle(new M.style.Polygon({
        fill: {
          color: '#0041ff',
          opacity: 0.6,
        },
        stroke: {
          width: 2,
          color: '#0041ff',
        },
      }));
    }
  } else {
    try {
      mapjs.removeLayers([coverage]);
    } catch (err) { }
  }

  if (zoom >= CONFIGURATIONS.fotograms_zoom && zoom <= CONFIGURATIONS.max_zoom) {
    mapjs.removeLayers([centers, marks]);
    const centersFeatures = sourceCenters.features.filter((f) => {
      return f.geometry !== null && ol.extent.containsCoordinate(getBbox(), ol.proj.fromLonLat(f.geometry.coordinates, PROJECTION));
    });

    const marksFeatures = sourceMarks.features.filter((f) => {
      let extent = [];
      if (f.geometry !== null && f.geometry.type === 'Polygon') {
        extent = ol.proj.transformExtent(new ol.geom.Polygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      } else if (f.geometry !== null) {
        extent = ol.proj.transformExtent(new ol.geom.MultiPolygon(f.geometry.coordinates).getExtent(), 'EPSG:4326', PROJECTION);
      }

      return ol.extent.intersects(getBbox(), extent);
    });

    centers = newGeoJSONLayer({ source: Object.assign({}, sourceCenters), extract: false, name: CONFIGURATIONS.centers_name }, { displayInLayerSwitcher: false });
    marks = newGeoJSONLayer({ source: Object.assign({}, sourceMarks), extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
    centers.source.features = centersFeatures;
    marks.source.features = marksFeatures;
    mapjs.addLayers(centers);
    mapjs.addLayers(marks);
    centers.setZIndex(999999999999999999);
    marks.setZIndex(999999999999999999);
    if (!mapContainsLayer(inverseCoverage.name)) {
      mapjs.addLayers(inverseCoverage);
      inverseCoverage.setZIndex(999999999999999999);
    }

    centers.setStyle(new M.style.Point({
      fill: {
        color: '#0041ff',
        opacity: 0.6,
      },
      stroke: {
        width: 2,
        color: '#0041ff',
      },
      radius: 7,
    }));

    marks.setStyle(new M.style.Polygon({
      fill: {
        color: '#0041ff',
        opacity: 0,
      },
    }));

    setTimeout(() => {
      centers.getFeatures().forEach((f) => {
        const selectedFeatures = getSelectedFeatures();
        if (Utils.arrayContainsString(selectedFeatures, f.getAttribute(CONFIGURATIONS.fotogram_layer_attr))) {
          f.setStyle(new M.style.Point({
            fill: {
              color: '#E74C3C',
              opacity: 0.6,
            },
            stroke: {
              color: '#E74C3C',
              width: 2,
            },
            radius: 7,
          }));
        }
      });

      if (inverseCoverage.getImpl().getOL3Layer() !== null) {
        inverseCoverage.getImpl().getOL3Layer().setStyle(new ol.style.Style({
          fill: new ol.style.FillPattern({
            pattern: 'crosses',
            color: '#E74C3C',
            scale: 2,
            offset: 0,
            background: '#E74C3C',
          }),
          stroke: new ol.style.Stroke({
            width: 2,
            color: '#E74C3C',
          }),
        }));
      }
    }, CONFIGURATIONS.highlight_selected_timeout);
  } else {
    try {
      mapjs.removeLayers([centers, marks, inverseCoverage]);
    } catch (err) { }
  }
}

const addVectorLayerFromFeatures = (layerName, features, type) => {
  if (type === 'uploaded') {
    layerName = 'uploadedLayer' + layerName;
  }

  const layer = new M.layer.Vector({ name: layerName, extract: false }, { displayInLayerSwitcher: type === 'uploaded' ? true : false });
  layer.extract = false;
  layer.addFeatures(features);
  mapjs.addLayers(layer);
  layer.setZIndex(9999999999);
  return layer;
}

const addConfiguredPlugins = (t) => {
  let prevConfigs = {
    calendar: true,
    scale: true,
    ignsearch: true,
    mousesrs: true,
    help: true,
    infocoordinates: true,
    measurebar: true,
    sharemap: true,
    printermap: true,
    zoompanel: true,
    rotate: true,
  };

  ApiService.getRequest(API_REST + 'api/configurations')
    .then((response) => {
      addPlugins(response, t);
    }).catch((error) => {
      addPlugins(prevConfigs, t);
    });
}

const isMobile = () => {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
  ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
  ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth);
}

const isMobile2 = () => {
  return localStorage.mobile || window.navigator.maxTouchPoints > 1 || (window.innerWidth < 1290 && window.innerHeight < 600);
}

const isSafari = () => {
  return window.navigator.userAgent.toLowerCase().indexOf('safari') > -1;
}

const addPlugins = (config, t) => {
  let mobile = isMobile();
  if (config.calendar === true) {
    mapjs.addPlugin(new M.plugin.Calendar({
      position: mobile ? 'BL' : 'TR'
    }));
  }

  if (mobile) {
    mapjs.addControls(['rotate*BR']);
  }

  if (config.help === true) {
    mapjs.addPlugin(new M.plugin.Modal({
      position: mobile ? 'BL' : 'TR',
      collapsed: true,
      url_es: API_REST + 'api/help/html/es',
      url_en: API_REST + 'api/help/html/en',
    }));
  }

  if (config.ignsearch === true) {
    mapjs.addPlugin(

      new M.plugin.Locator({
        position: 'TC',
        collapsible: true,
        collapsed: true,
        zoom: 16,
        pointStyle: 'pinRojo',
        byParcelCadastre: true,
        byCoordinates: true,
        byPlaceAddressPostal: {
          maxResults: 33,
          reverse: true,
        },
        isDraggable: false,
      })

    );
  }

  if (config.infocoordinates === true && !mobile) {
    mapjs.addPlugin(new M.plugin.Infocoordinates({
      position: 'TR',
      decimalGEOcoord: 6,
      decimalUTMcoord: 2,
    }));
  }

  if (config.measurebar === true) {
    mapjs.addPlugin(new M.plugin.MeasureBar({
      position: mobile ? 'BL' : 'TR'
    }));
  }

  if (config.mousesrs === true && !mobile) {
    mapjs.addPlugin(new M.plugin.MouseSRS({
      position: 'BL',
      tooltip: t('visor.coordinates'),
      srs: 'EPSG:4326',
      label: 'EPSG:4326',
      precision: 4,
      geoDecimalDigits: 5,
      utmDecimalDigits: 2,
    }));
  }

  if (config.printermap === true && !mobile) {
    mapjs.addPlugin(new M.plugin.PrinterMap({
      position: 'TR',
      collapsed: true,
      credits: t('visor.printed'),
      georefActive: true,
      fototeca: true,
    }));
  }

  if (config.scale === true && !mobile) {
    mapjs.addControls(['scale*true']);
  }

  if (config.sharemap === true && !mobile) {
    mapjs.addPlugin(new M.plugin.ShareMap({ position: 'BR' }));
  }

  if (config.zoompanel === true && !mobile) {
    mapjs.addPlugin(new M.plugin.ViewManagement({
      position: 'TR',
      collapsible: true,
      collapsed: true,
      predefinedZoom: [{
        name: 'zoom 1',
        center: [-428106.86611520057, 4334472.25393817],
        zoom: 5,
      }],
      zoomExtent: true,
      viewhistory: false,
      zoompanel: true,
      isDraggable: false,
    }));
  }

  //Se reposiciona el control rotate
  const controlRotate = document.querySelector('.m-panel.m-rotate');
  if (controlRotate) {
    const areaBR = document.querySelector('.m-area.m-bottom.m-right');
    controlRotate.parentNode.removeChild(controlRotate)
    areaBR.appendChild(controlRotate);
  }
}

const addAdditionalLayers = (map, layers, callback) => {
  const lang = LocalStorage.getString('language') || 'es';
  ApiService.getRequest(API_REST + 'api/additionallayer')
    .then((response) => {
      response.forEach(al => {
        addWMSLayer(map, al.name, (lang === 'es' ? al.title : al.titleEn), al.url, al.version, al.tiled, { visibility: layers.indexOf(al.name) > -1, displayInLayerSwitcher: true, queryable: al.queryable, zIndex: (8000 + al.sort) });
      });

      if (callback !== undefined) {
        setTimeout(callback, 2000);
      }
    }).catch((error) => {
      console.error(error);
    });
}

const addConfiguredBaseLayersPlugin = (map, defaultBase, callback) => {
  const lang = LocalStorage.getString('language') || 'es';
  ApiService.getRequest(API_REST + 'api/backgroundlayer')
    .then((response) => {
      const layerOpts = [];
      response.forEach((l) => {
        let lo = {
          id: 'backgroundLayer' + l.id,
          preview: l.image,
          title: lang === 'es' ? l.title : l.titleEn,
        };

        if (l.type === 'WMTS') {
          lo.layers = [
            new M.layer.WMTS({
              url: l.url,
              name: l.name,
              legend: lang === 'es' ? l.title : l.titleEn,
              matrixSet: 'GoogleMapsCompatible',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              format: l.format,
            }),
          ];
        } else if (l.type === 'XYZ') {
          lo.layers = [
            new M.layer.XYZ({
              url: l.url,
              name: l.name,
              legend: lang === 'es' ? l.title : l.titleEn,
              projection: 'EPSG:3857',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              tileGridMaxZoom: l.tilegridMaxZoom,
            }),
          ];
        }

        layerOpts.push(lo);
      });

      map.addPlugin(new M.plugin.BackImgLayer({
        position: isMobile() ? 'BR' : 'TR',
        layerId: defaultBase || 0,
        layerVisibility: true,
        collapsed: true,
        collapsible: true,
        columnsNumber: 3,
        addToc: true,
        layerOpts,
      }));

      if (callback !== undefined) {
        callback();
      }
    }).catch((error) => {
      console.error(error);
    });
}

const removeMapOverlays = () => {
  const overlays = mapjs.getMapImpl().getOverlays().getArray();
  overlays.forEach(item => {
    if (item.getKeys().indexOf('element') > -1 && item.element.innerHTML.indexOf('m-measure') < 0 && item.element.innerHTML.indexOf('ignsearchlocator') < 0 && item.element.innerHTML.indexOf('contenedorPunto') < 0) {
      mapjs.getMapImpl().removeOverlay(item);
    }
  });
}

const centerFeatures = (features) => {
  if (!M.utils.isNullOrEmpty(features)) {
    if (features.length === 1 && features[0].getGeometry().type.indexOf('Point') > -1) {
      mapjs.setCenter(features[0].getGeometry().coordinates);
      mapjs.setZoom(16);
    } else {
      const extent = M.impl.utils.getFeaturesExtent(features);
      mapjs.getMapImpl().getView().fit(extent, {
        duration: 500,
        minResolution: 1,
      });
    }
  }
}

const convertToMFeatures = (features) => {
  if (features instanceof Array) {
    return features.map((olFeature) => {
      const feature = new M.Feature(olFeature.getId(), {
        geometry: {
          coordinates: olFeature.getGeometry().getCoordinates(),
          type: olFeature.getGeometry().getType(),
        },
        properties: olFeature.getProperties(),
      });

      feature.getImpl().getOLFeature().setStyle(olFeature.getStyle());
      return feature;
    });
  }
}

const loadGeoJSONLayer = (layerName, source) => {
  let features = new ol.format.GeoJSON().readFeatures(source, { featureProjection: mapjs.getProjection().code });
  features.forEach((feature) => {
    const style1 = new ol.style.Style({
      stroke: new ol.style.Stroke({ color: '#0000FF', width: 8 }),
      fill: new ol.style.Fill({ color: 'rgba(0, 0, 255, 0.2)' }),
    });

    const style2 = new ol.style.Style({
      image: new ol.style.Circle({
        radius: 6,
        stroke: new ol.style.Stroke({
          color: 'white',
          width: 2,
        }),
        fill: new ol.style.Fill({
          color: '#7CFC00',
        }),
      }),
    });

    if (feature.getGeometry().getType() !== 'Point' && feature.getGeometry().getType() !== 'MultiPoint') {
      feature.setStyle(style1);
    } else {
      feature.setStyle(style2);
    }
  });

  features = convertToMFeatures(features);
  let area = 0;
  features.forEach((f) => {
    if (Utils.stringContainsString(f.getGeometry().type, 'polygon')) {
      area += ol.extent.getArea(M.impl.utils.getFeaturesExtent([f]));
    }
  });

  if (features.length > 0 && features.length <= CONFIGURATIONS.num_features_allowed && area <= CONFIGURATIONS.max_area_allowed) {
    addVectorLayerFromFeatures(layerName, features, 'uploaded');
  }

  return features;
}

const loadKMLLayer = (layerName, source, extractStyles) => {
  let features = new ol.format.KML({ extractStyles }).readFeatures(source, { featureProjection: mapjs.getProjection().code });
  features.forEach((feature) => {
    const style1 = new ol.style.Style({
      stroke: new ol.style.Stroke({ color: '#0000FF', width: 8 }),
      fill: new ol.style.Fill({ color: 'rgba(0, 0, 255, 0.2)' }),
    });

    const style2 = new ol.style.Style({
      image: new ol.style.Circle({
        radius: 6,
        stroke: new ol.style.Stroke({
          color: 'white',
          width: 2,
        }),
        fill: new ol.style.Fill({
          color: '#7CFC00',
        }),
      }),
    });

    if (feature.getGeometry().getType() !== 'Point' && feature.getGeometry().getType() !== 'MultiPoint') {
      feature.setStyle(style1);
    } else {
      feature.setStyle(style2);
    }
  });
  features = convertToMFeatures(features);
  let area = 0;
  features.forEach((f) => {
    if (Utils.stringContainsString(f.getGeometry().type, 'polygon')) {
      area += ol.extent.getArea(M.impl.utils.getFeaturesExtent([f]));
    }
  });

  if (features.length > 0 && features.length <= CONFIGURATIONS.num_features_allowed && area <= CONFIGURATIONS.max_area_allowed) {
    addVectorLayerFromFeatures(layerName, features, 'uploaded');
  }

  return features;
}

const loadGPXLayer = (layerName, source) => {
  let features = new ol.format.GPX().readFeatures(source, { featureProjection: mapjs.getProjection().code });
  features.forEach((feature) => {
    const style1 = new ol.style.Style({
      stroke: new ol.style.Stroke({ color: '#0000FF', width: 8 }),
      fill: new ol.style.Fill({ color: 'rgba(0, 0, 255, 0.2)' }),
    });

    const style2 = new ol.style.Style({
      image: new ol.style.Circle({
        radius: 24,
        stroke: new ol.style.Stroke({
          color: 'white',
          width: 2,
        }),
        fill: new ol.style.Fill({
          color: '#7CFC00',
        }),
      }),
    });

    if (feature.getGeometry().getType() !== 'Point' && feature.getGeometry().getType() !== 'MultiPoint') {
      feature.setStyle(style1);
    } else {
      feature.setStyle(style2);
    }
  });
  features = convertToMFeatures(features);
  let area = 0;
  features.forEach((f) => {
    if (Utils.stringContainsString(f.getGeometry().type, 'polygon')) {
      area += ol.extent.getArea(M.impl.utils.getFeaturesExtent([f]));
    }
  });

  if (features.length > 0 && features.length <= CONFIGURATIONS.num_features_allowed && area <= CONFIGURATIONS.max_area_allowed) {
    addVectorLayerFromFeatures(layerName, features, 'uploaded');
  }

  return features;
}

const controlDrawWithKeyboard = (draw, e) => {
  if (draw !== undefined && e !== undefined) {
    const key = e.keyCode || e.which;
    if (key === 27) {
      if (document.getElementById('drawPolygon').style.color === 'white') {
        document.getElementById('drawPolygon').click();
      }
    }

    if (key === 13) {
      draw.finishDrawing();
    }
  }
};

const showInfoPopup = (feature, t) => {
  removeMapOverlays();
  const overlay = new ol.Overlay({
    element: getInfoPopupContent(feature, t),
    stopEvent: true,
    autoPan: false,
    offset: [-10, 10],
    position: feature.getGeometry().getCoordinates(),
    positioning: 'top-right',
  });

  mapjs.getMapImpl().addOverlay(overlay);
  document.getElementById('overlay-close-button').addEventListener('click', removeMapOverlays);
};

const getInfoPopupContent = (feature, t) => {
  const attributes = feature.getProperties();
  let attr = [];
  for (let key in attributes) {
    const label = getLabel(key, t);
    if (label.position !== undefined && attributes[key] !== undefined && attributes[key] !== null && attributes[key] !== '') {
      attr[label.position] = <p class='popup-overlay-info-attr' key={key}><b>{label.name}:&nbsp;&nbsp;</b>{attributes[key]}</p>;
    }
  }

  const htmlString = reactElementToJSXString(
    <div id='popup-overlay' class='popup-overlay-container'>
      <div class='popup-overlay-title'>
        {t('visor.photo') + ' (' + t('visor.aprox_position') + ')'}
        <button id='overlay-close-button' class='popup-overlay-close-button'>
          X
        </button>
      </div>
      <div class='popup-overlay-info-container'>
        <div>
          {attr}
        </div>
      </div>
    </div>
  );

  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.getElementById('popup-overlay');
}

const getLabel = (nameField, t) => {
  let name = nameField;
  let position = undefined;
  switch (nameField) {
    case 'nom_fichero':
      name = t('visor.file');
      position = 0;
      break;
    case 'nom_vuelo':
      name = t('info.flight');
      position = 1;
      break;
    case 'fecha_fotograma':
      name = t('info.date');
      position = 3;
      break;
    default:
      break;
  }

  return { name: name, position: position };
};
