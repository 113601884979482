export default {
  actions: {
    max_size_file: 'El fichero seleccionado sobrepasa el máximo de * MB permitido',
    file_type: 'El tipo de fichero seleccionado no está entre la lista de formatos permitidos.',
    warning: 'Advertencia',
    search_fotograms: 'Consulta de fotogramas',
    using_geometry: 'Buscar usando la geometría de un fichero local',
    allowed_formats: 'Formatos permitidos: .shp comprimido en .zip, .kml, .gpx y .geojson.',
    srs: 'El Sistema de Referencia del archivo se adaptará al del visor.',
    tools: 'Buscar fotogramas',
    view_extend: 'Buscar en la vista actual',
    polygons: 'Buscar por polígono',
    mtn_sheet: 'Buscar por hoja del MTN',
    remove_criteria: 'Eliminar el criterio de búsqueda',
    load_selection: 'Listar fotogramas',
    available_scale_1: 'Esta herramienta se habilita a una escala',
    available_scale_2: 'o de mayor detalle (nivel de provincia).',
    sheet_not_found: 'Hoja no encontrada, revise la consulta.',
    select_flight: 'Esta herramienta se habilita cuando se selecciona algún vuelo.',
    under_develop: 'En desarrollo...',
  },
  checkbox: {
    select: 'Seleccionar',
    quit: 'Quitar selección',
  },
  filters: {
    search_flights: 'Buscar vuelos',
    text: 'Texto a buscar',
    flight_type: 'Tipo de vuelo',
    select: 'Seleccionar',
    analogic: 'Analógico',
    digital: 'Digital',
    scale: 'Escala',
    date: 'Año de vuelo',
    search: 'Buscar',
    clean: 'Limpiar',
    please: 'Por favor, introduzca algún criterio de búsqueda.',
    check: 'Comprueba que la escala y el año de vuelo están en el formato correcto.',
    gsd: 'GSD (Píxel en terreno en cm)',
    export: 'Exportar',
    mtn_sheet: 'Buscar por hoja MTN',
    view_extend: 'Buscar en la vista actual',
    on_view: 'Sobre la vista',
  },
  footer: {
    no_coverage: 'No hay cobertura de este vuelo en la zona de la vista',
    historical: 'HISTÓRICOS',
    pnoa: 'PNOA',
    photos: 'FOTOGRAMAS',
    ortophotos: 'ORTOFOTOS',
  },
  header: {
    title: 'Fototeca Digital',
    subtitle: 'Visualizador de fotogramas',
    change_language: 'Cambiar idioma',
    accept: 'Aceptar',
    cart: 'Carrito de la compra',
    last_news: 'Noticias de la Fototeca Digital',
    warning: 'Advertencia',
    empty_cart: 'No se han añadido certificaciones al carrito',
  },
  info: {
    fotogram: 'Fotograma',
    flight: 'Vuelo',
    available_formats: 'Disponibilidad según formatos',
    oe: 'O. E.',
    extension: 'Extensión',
    coverage: 'Cobertura',
    file_name: 'Nombre de fichero',
    format: 'Formato',
    code: 'Código',
    past: 'Pasada',
    date: 'Fecha',
    x_coord: 'Coordenada X fotocentro',
    y_coord: 'Coordenada Y fotocentro',
    pixel_size: 'Tamaño de píxel en la imagen',
    name: 'Nombre',
    type: 'Tipo',
    description: 'Descripción',
    date_flight: 'Se voló en',
    observations: 'Observaciones',
    aux_info: 'Información auxiliar',
    scale: 'Escala',
    yes: 'Sí',
    no: 'No',
    length_cover: 'Recubrimiento longitudinal',
    trans_cover: 'Recubrimiento transversal',
    pixel_size_terrain: 'Tamaño de píxel en el terreno',
    no_extension: 'Este vuelo no tiene una extensión asociada',
    tiff_rgb: 'TIF con bandas RGB',
    tiff_rgbi: 'TIF con bandas RGBI',
    tiff_ir: 'TIF con bandas IR',
    tiff_irg: 'TIF con bandas IRG',
    tiff_pan: 'TIF con banda pancromática',
    comp_rgb: 'Formato comprimido con bandas RGB',
    comp_rgbi: 'Formato comprimido con bandas RGBI',
    comp_ir: 'Formato comprimido con bandas IR',
    comp_irg: 'Formato comprimido con bandas IRG',
    comp_pan: 'Formato comprimido con banda pancromática',
    photos: 'Fotogramas',
    ortophotos: 'Ortofotos',
    centimeters: 'centímetros',
    microns: 'micras',
    copy: 'Copiar',
    availability: 'El servicio que ofrece este visualizador únicamente permite descargar fotogramas en <b>formato comprimido con bandas RGB.</b> El resto de formatos se pueden solicitar en el correo electrónico <a href="mailto:fototeca@cnig.es">fototeca@cnig.es</a>',
    oe_parameters: 'Parámetros de Orientación Externa (AT)',
    oe_text: 'Datos extraídos de los procesos de generación de ortofotos.<br/> Adicionalmente existe un servicio <a href="*" target="_blank" rel="noopener noreferrer">REST</a> que permite obtener los datos de Orientación Externa de un conjunto de fotogramas.',
  },
  list: {
    my_list: 'Mi lista de fotogramas',
    fotograms: 'Fotogramas',
    opacity: 'Opacidad',
    actions: 'Acciones',
    selected: 'Fotogramas seleccionados',
    downloadables: 'Fotogramas a descargar',
    remove: 'Borrar',
    download: 'Descargar',
    compare: 'Comparar',
    compare_fotograms: 'Comparación de fotogramas',
    obtain: 'Obtener listado',
    print: 'Imprimir PDF y Certificar',
    print_only: 'Imprimir',
    info: 'Información del Fotograma',
    please_wait: 'Por favor, espere...',
    not_area: 'No existe fotograma en el área seleccionada',
    error: 'Ocurrió un error realizando la impresión. Por favor, inténtelo de nuevo en unos minutos y si el error persiste contacte con nosotros.',
    error2: 'No ha sido posible realizar la certificación. Por favor, contacte con nosotros a través del correo electrónico <a href="mailto:fototeca@cnig.es">fototeca@cnig.es</a>',
    under_develop: 'En desarrollo...',
    products_added: 'Se han añadido productos al carrito',
    warning: 'Aviso',
    available_flights: 'Vuelos disponibles',
    delete: 'Eliminar',
    sure_delete_1: 'Está a punto de eliminar todos los fotogramas de la lista y existen fotogramas de varios vuelos ¿seguro que quiere continuar?',
    sure_delete_2: 'Está a punto de eliminar todos los fotogramas de la lista, ¿seguro que quiere continuar?',
    remove_selection: 'Borrar selección',
    remove_all: 'Borrar todo',
    some_downloaded: 'Algunos de los fotogramas consultados no dispone de parámetros de orientación externa',
    none_downloaded: 'Ninguno de los fotogramas consultados dispone de parámetros de orientación externa',
    oe_error: 'No se han podido consultar los parámetros de orientación externa',
    oe: 'Orientación Externa',
    obtain_fotograms: 'Obtener el listado de los fotogramas',
    obtain_oe: 'Obtener el listado de parámetros de OE de los fotogramas',
    no_results: 'Su lista de fotogramas está vacía. Utilice las herramientas de búsqueda.',
    show_fotogram: 'El fotograma está visible',
  },
  menu: {
    search_on_view: 'Buscar fotogramas',
    close: 'Cerrar',
  },
  mtnSelector: {
    grid: 'Cuadrícula',
    number: 'Número',
    quarter: 'Cuarto',
    show_sheet: 'Mostrar Hoja',
    number_50: 'Número de Hoja del MTN50',
  },
  pagination: {
    of: ' de ',
  },
  printSelection: {
    size: 'Tamaño',
    info: 'Información',
    step1: '1. Haz click en el recuadro.',
    step2: '2. Arrastra el recuadro a la zona.',
    step3: '3. Suelta el recuadro.',
    paper: 'Papel',
    scale: 'Escala',
    custom: 'Personalizada',
    orientation: 'Orientación',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    request_certificate: 'Solicitar Certificado',
    digital: 'Digital',
    accept: 'Aceptar',
    certificate_text: 'Este servicio es opcional y tiene un <a target="_blank" rel="noopener noreferrer" href="https://www.ign.es/web/resources/fototeca/precios-fototeca.pdf">coste adicional</a>. Para certificar la impresión marque Papel o Digital.<br/><br/>Una vez solicitado el certificado seleccione el carrito para ver su pedido en nuestra Tienda Virtual.',
  },
  results: {
    analogic: 'Analógico',
    digital: 'Digital',
    search_data: 'Información del Vuelo',
    results: 'Resultados',
    name: 'Nombre',
    type: 'Tipo',
    actions: 'Acciones',
    flight_info: 'Información del Vuelo',
    year: 'Año',
    scale: 'Escala / GSD',
    check_available: 'Consulte las observaciones del vuelo para saber si está disponible',
  },
  searchMenu: {
    available_flights: 'Buscar vuelos en el Archivo de la Fototeca',
    close: 'Cerrar',
    other_flights: 'Otros vuelos',
  },
  tableRow: {
    fotogram: 'Fotograma',
    flight: 'Vuelo',
    show: 'Mostrar',
    hide: 'Ocultar',
    info: 'Consultar datos',
    award: 'Imprimir / Certificar',
    delete: 'Eliminar',
    zoom: 'Zoom al fotograma',
  },
  compareSelector: {
    compare: 'Abrir comparador',
    text_description: 'Seleccione los fotogramas de un vuelo para cada pantalla para poder compararlos',
    screen1: 'Pantalla 1',
    screen2: 'Pantalla 2',
    show: 'Mostrar',
    hide: 'Ocultar',
  },
  visor: {
    coordinates: 'Coordenadas',
    zoom_extent: 'Zoom a la extensión del mapa',
    share_url: 'Compartir URL',
    copied: 'Copiado',
    accept: 'Aceptar',
    copy: 'Copiar',
    wrong_format: 'Ha introducido un archivo con un formato no válido.',
    no_geom: 'No se han detectado geometrías en este fichero.',
    max_allowed1: 'No se admiten ficheros con más de',
    max_allowed2: 'geometrías distintas.',
    area_limit: 'El área de consulta supera el límite permitido.',
    error_loading: 'Error al cargar el fichero. Compruebe que se trata del fichero correcto.',
    file: 'Fichero',
    aprox_position: 'posición aproximada',
    photo: 'Foto',
    american_flight: 'Vuelo Americano',
    national_flight: 'Vuelo Nacional',
    interministerial_flight: 'Vuelo Interministerial',
    cadastre: 'Catastro',
    mtn25: 'Cuadrícula cartográfica del MTN25',
    mtn50: 'Cuadrícula cartográfica del MTN50',
    administrative_units: 'Unidades administrativas',
    printed: 'Impresión generada desde Fototeca Digital https://fototeca.cnig.es/',
    coverage: "Comprobando cobertura en la zona...",
    no_coverage: "Esta capa no tiene cobertura en esta zona",
    warning: "AVISO",
  },
  signin: {
    admin_access: 'ACCESO A LA ADMINISTRACIÓN',
    user: 'Usuario',
    password: 'Contraseña',
    access: 'Acceder',
    login_success_title: 'Correcto',
    login_success: 'Se ha iniciado sesión correctamente',
    error: 'Error',
    login_error: 'No se pudo iniciar sesión, compruebe los datos de acceso',
    empty_fields: 'Rellene los campos para poder iniciar sesión',
  },
  administration: {
    dashboard: 'PANEL DE ADMINISTRACIÓN',
    flights: 'VUELOS',
    mosaics: 'ORTOFOTOS',
    backgroundlayers: 'CAPAS BASE',
    additionallayers: 'CAPAS ADICIONALES',
    help: 'AYUDA',
    others: 'HERRAMIENTAS',
    logout: 'SALIR',
  },
  backgroundlayerForm: {
    type: 'Tipo',
    name: 'Nombre',
    title: 'Título (es)',
    titleEn: 'Título (en)',
    maxZoom: 'Máximo zoom para cargar tiles',
    imageFormat: 'Formato de imagen',
    thumbnail: 'URL miniatura',
    save: 'GUARDAR',
    error: '*Hay campos sin rellenar',
  },
  backgroundlayers: {
    edit: 'Editar',
    remove: 'Eliminar',
    backgroundlayers: 'CAPAS BASE',
    thumbnail: 'Miniatura',
    type: 'Tipo',
    title: 'Título (es)',
    titleEn: 'Título (en)',
    name: 'Nombre',
    imageFormat: 'Formato de imagen',
    maxZoom: 'Máx. zoom para cargar tiles',
    actions: 'Acciones',
    addLayer: 'AÑADIR CAPA BASE',
    layerData: 'DATOS DE CAPA BASE',
    up: 'Subir',
    down: 'Bajar',
    error: 'Error',
    load_error: 'No se han podido cargar los datos',
    edit_error: 'No se han podido realizar las modificaciones',
    save_error: 'No se han podido guardar los datos de la capa base',
    delete_error: 'No se ha podido eliminar la capa base',
    saved: 'Guardado',
    changes_saved: 'Modificaciones realizadas con éxito',
    created: 'Se ha guardado la nueva capa base',
    removed: 'Se ha eliminado correctamente la capa base',
  },
  flightForm: {
    historic: 'Históricos',
    group: 'Grupo',
    title: 'Título',
    subtitle: 'Subtítulo',
    descriptionEs: 'Descripción (es)',
    descriptionEn: 'Descripción (en)',
    wmsName: 'Nombre de capa en servidor WMS',
    fileName: 'Nombre de ficheros GEOJSON',
    certifiable: '¿Es certificable?',
    printable: '¿Es imprimible en papel?',
    addValue: 'Añadir valor',
    keys: 'Palabras claves para ubicar fotograma del vuelo',
    scales: 'Escalas de impresión disponibles',
    sizes: 'Tamaños de impresión disponibles',
    save: 'GUARDAR',
    error: '*Hay campos sin rellenar',
  },
  flightsConfig: {
    historic: 'HISTÓRICOS',
    up: 'Subir',
    down: 'Bajar',
    seeCoverage: 'Ver cobertura',
    edit: 'Editar',
    remove: 'Eliminar',
    flights: 'VUELOS',
    title: 'Título',
    subtitle: 'Subtítulo',
    descriptionEs: 'Descripción (es)',
    descriptionEn: 'Descripción (en)',
    certifiable: 'Certificable',
    scales: 'Escalas de impresión',
    sizes: 'Tamaños de impresión',
    printable: 'Imprimible en papel',
    actions: 'Acciones',
    addFlight: 'AÑADIR VUELO',
    flightCoverage: 'COBERTURA DEL VUELO',
    flightData: 'DATOS DE VUELO',
    groupData: 'DATOS DE GRUPO',
    groups: 'AGRUPACIONES',
    addGroup: 'AÑADIR AGRUPACIÓN',
    error: 'Error',
    load_error: 'No se han podido cargar los datos',
    edit_error: 'No se han podido realizar las modificaciones',
    save_error: 'No se han podido guardar los datos',
    delete_error: 'No se han podido eliminar los datos',
    saved: 'Guardado',
    changes_saved: 'Modificaciones realizadas con éxito',
    created: 'Se han guardado los datos',
    removed: 'Se han eliminado los datos',
    warning: 'Advertencia',
    group_not_empty: 'No se puede eliminar el grupo, tiene vuelos asociados',
    name: 'Nombre',
    show: 'Mostrar',
    hide: 'Ocultar',
  },
  help: {
    help: 'AYUDA',
    textEs: 'Texto en español',
    textEn: 'Texto en inglés',
    saveChanges: 'GUARDAR CAMBIOS',
    saved: 'Guardado',
    changes_saved: 'Modificaciones realizadas con éxito',
    error: 'Error',
    save_error: 'No se han podido guardar las modificaciones',
  },
  mosaicForm: {
    historic: 'Históricos',
    group: 'Grupo',
    legend: 'Leyenda',
    name: 'Nombre',
    version: 'Versión',
    descriptionEs: 'Descripción (es)',
    descriptionEn: 'Descripción (en)',
    save: 'GUARDAR',
    error: '*Hay campos sin rellenar',
  },
  mosaicsConfig: {
    historic: 'HISTÓRICOS',
    up: 'Subir',
    down: 'Bajar',
    edit: 'Editar',
    remove: 'Eliminar',
    mosaics: 'ORTOFOTOS',
    legend: 'Leyenda',
    name: 'Nombre',
    version: 'Versión',
    descriptionEs: 'Descripción (es)',
    descriptionEn: 'Descripción (en)',
    order: 'Orden',
    actions: 'Acciones',
    addMosaic: 'AÑADIR ORTOFOTO',
    mosaicData: 'DATOS DE ORTOFOTO',
    groupData: 'DATOS DE GRUPO',
    groups: 'AGRUPACIONES',
    addGroup: 'AÑADIR AGRUPACIÓN',
    error: 'Error',
    load_error: 'No se han podido cargar los datos',
    edit_error: 'No se han podido realizar las modificaciones',
    save_error: 'No se han podido guardar los datos',
    delete_error: 'No se han podido eliminar los datos',
    saved: 'Guardado',
    changes_saved: 'Modificaciones realizadas con éxito',
    created: 'Se han guardado los datos',
    removed: 'Se han eliminado los datos',
    warning: 'Advertencia',
    group_not_empty: 'No se puede eliminar el grupo, tiene ortofotos asociadas',
    show: 'Mostrar',
    hide: 'Ocultar',
  },
  others: {
    others: 'HERRAMIENTAS',
    saveChanges: 'GUARDAR CAMBIOS',
    searchTools: 'Herramientas para buscar fotogramas',
    searchByView: 'Buscar en la vista',
    searchByDraw: 'Buscar por polígono',
    searchByMTN: 'Buscar por hoja del MTN',
    searchByFile: 'Buscar por fichero local',
    photogramActions: 'Acciones sobre fotogramas',
    photogramViewHide: 'Ver / Ocultar',
    photogramInfo: 'Consultar datos',
    photogramZoom: 'Zoom',
    photogramCertify: 'Imprimir / Certificar',
    mapTools: 'Herramientas de mapa',
    mousesrs: 'Coordenadas de ratón',
    scale: 'Control de escala',
    sharemap: 'Compartir',
    calendar: 'Cita previa',
    help: 'Más información',
    infocoordinates: 'Información coordenadas',
    printermap: 'Impresión',
    measurebar: 'Mediciones',
    zoompanel: 'Panel de zooms',
    ignsearch: 'Búsquedas',
    saved: 'Guardado',
    changes_saved: 'Modificaciones realizadas con éxito',
    error: 'Error',
    save_error: 'No se han podido guardar las modificaciones',
    showup: 'Desplegar',
    returnmap: 'Volver al mapa',
    showfilters: 'Mostrar filtros',
  },
  rss: {
    subscribe: 'Suscribirse',
    filter: 'Filtrar',
    search: 'Buscar contenido',
    clear: 'Limpiar',
    from: 'Desde',
    to: 'Hasta',
    no_results: 'No se han encontrado resultados',
  },
  additionallayers: {
    edit: 'Editar',
    remove: 'Eliminar',
    additionallayers: 'CAPAS ADICIONALES',
    title: 'Título (es)',
    titleEn: 'Título (en)',
    name: 'Nombre',
    version: 'Versión',
    queryable: 'Consultable',
    tiled: 'Tileada',
    actions: 'Acciones',
    addLayer: 'AÑADIR CAPA ADICIONAL',
    layerData: 'DATOS DE CAPA ADICIONAL',
    up: 'Subir',
    down: 'Bajar',
    error: 'Error',
    load_error: 'No se han podido cargar los datos',
    edit_error: 'No se han podido realizar las modificaciones',
    save_error: 'No se han podido guardar los datos de la capa adicional',
    delete_error: 'No se ha podido eliminar la capa adicional',
    saved: 'Guardado',
    changes_saved: 'Modificaciones realizadas con éxito',
    created: 'Se ha guardado la nueva capa adicional',
    removed: 'Se ha eliminado correctamente la capa adicional',
  },
  additionallayerForm: {
    name: 'Nombre',
    title: 'Título (es)',
    titleEn: 'Título (en)',
    version: 'Versión',
    queryable: '¿Es consultable?',
    tiled: '¿Debe cargarse tileada?',
    save: 'GUARDAR',
    error: '*Hay campos sin rellenar',
  },
  rssconfig: {
    edit: 'Editar',
    remove: 'Eliminar',
    title: 'Título',
    description: 'Descripción',
    pubDate: 'Fecha de publicación',
    addNew: 'AÑADIR NOTICIA',
    newData: 'DATOS DE NOTICIA',
    actions: 'Acciones',
    error: 'Error',
    load_error: 'No se han podido cargar los datos',
    edit_error: 'No se han podido realizar las modificaciones',
    save_error: 'No se han podido guardar los datos de la noticia',
    delete_error: 'No se ha podido eliminar la noticia',
    saved: 'Guardado',
    changes_saved: 'Modificaciones realizadas con éxito',
    created: 'Se ha guardado la nueva noticia',
    removed: 'Se ha eliminado correctamente la noticia',
  },
  rssconfigform: {
    title: 'Título',
    description: 'Descripción',
    save: 'GUARDAR',
    error: '*Hay campos sin rellenar',
  },
  groupform: {
    name: 'Nombre',
    save: 'GUARDAR',
    error: '*Hay campos sin rellenar',
  },
  shoppingCart: {
    delete: 'Eliminar',
    product: 'Producto',
    fotogram: 'Fotograma',
    actions: 'Acciones',
    buy: 'Tramitar compra',
    show: 'Ver',
  },
};
